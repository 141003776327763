@import "./assets/vrr/scss/styles.scss";

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: "megabyte-regular", -apple-system, BlinkMacSystemFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;

  #root {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

code {
  font-family: "Megabyte-Bold";
}
