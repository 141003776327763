@import './../../../../../../../assets/vrr/scss/styles.scss';

.target-list-container {
  margin: 1.5rem 0;

  .target-list-header {
    display: flex;
    justify-content: space-between;
    border-bottom: solid 0.1rem $vrr-grey-input;
    p {
      color: $vrr_grey_medium;
    }
    .clear-btn {
      text-decoration: underline;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .target-list-tags {
    padding-top: 0.7rem;
    div {
      margin: 0.3rem;
    }
  }
}
