@import './../../../../../assets/vrr/scss/styles.scss';

.media-plan-card-body {
  display: flex;
  justify-content: space-around;
  line-height: 1.4rem;

  .media-plan-card-body-left,
  .media-plan-card-body-right {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .media-plan-card-body-left {
    justify-content: space-between;
  }

  .media-plan-card-body-left {
    align-items: center;
    color: $vrr_grey_card;

    .media-plan-card-body-funnel {
      &.active {
        font-weight: bold;

        &.purple {
          color: $vrr_mauve;
        }

        &.red {
          color: $vrr-red;
        }

        &.orange {
          color: $vrr_orange;
        }
      }
    }
  }

  .media-plan-card-body-right {
    width: 50%;

    .media-plan-card-body-right-infos {
      border-bottom: 0.001rem solid $vrr_grey_medium2;
      display: flex;
      justify-content: space-between;
      color: $vrr_navy;
      gap: 2rem;
      .media-plan-card-body-right-label {
        font-size: smaller;
        color: $vrr_grey_card;
        font-weight: bolder;
      }

      .media-plan-card-offer {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
