@import './../../../../../assets/vrr/scss/styles.scss';

.media-plan-card-header {
  display: inline-block;
  overflow: hidden;
  position: relative;
  border-radius: 0.5rem;
  z-index: 0;
  height: 6rem;
  margin: 0.5rem;

  .media-plan-product {
    pointer-events: none;
    position: absolute;
    z-index: -2;
    top: 50%;
    transform: translate(0%, -50%);
  }

  .media-plan-background {
    background: rgba(70, 71, 103, 0.2);
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .media-plan-card-title {
    position: absolute;
    z-index: 1;
    padding: 1rem;

    p,
    span {
      text-shadow: .05rem .05rem .05rem $vrr-black;
      color: $vrr_white;
    }

    p {
      margin-bottom: 0;
      font-weight: 900;
    }
  }

  span {
    margin-top: 0;
    font-size: xx-small;
  }
}
