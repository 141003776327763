@import './../../../../../../../assets/vrr/scss/styles.scss';

.pixel-modal {
  &.modal-dialog {
    max-width: 50rem !important;

    i.circle-icon {
      background-color: $navy-blue-gradient;
      color: $vrr_white;
      padding: 0.5rem;
      border-radius: 50%;
      margin-right: 1rem;
    }
    .pixel-modal-header {
      padding: 1rem;
    }
    .pixel-body {
      padding: 2rem 4rem;
    }
  }
}
