@mixin overview-position {
  display: flex;
  flex-direction: column;
  gap: 0.9rem;
  h4 {
    margin-bottom: 0;
  }
}

main.overview-main-section {
  display: flex;
  flex-direction: column;
  gap: 2.813rem;
  .global-metrics-part {
    @include overview-position();
  }

  .global-insights-part {
    @include overview-position();
  }

  .right-bar-position {
    position: fixed;
    right: 0;
    top: 0;
  }
}
