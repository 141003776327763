@import './../../../../../../../../../../../../../assets/vrr/scss/styles.scss';

.audience-category-line {
  input[type='checkbox'] {
    opacity: 0;
  }

  label.check {
    position: relative;
    margin-bottom: 0;
    span.category-line-name {
      font-family: 'Megabyte-Medium';
      color: $vrr_grey_medium;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  input[type='checkbox'] + label.check span.cyan-checkbox {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    min-width: 0.938rem;
    min-height: 0.938rem;
    background-color: transparent;
    border: solid 0.063rem $vrr_grey_medium;
    border-radius: 0.188rem;
    font-size: 0.7rem;
    line-height: 0.938rem;
  }

  input[type='checkbox']:checked + label.check span.cyan-checkbox::after {
    content: '\2713';
    color: $vrr_white;
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  input[type='checkbox']:checked + label.check span.cyan-checkbox {
    border: none;

    &.cyan-checkbox {
      background-color: $vrr_cyan;
    }
  }
}
