@import '../../../../../../../assets/vrr/scss/styles';

.url-form-add-button {
  padding: 0.5rem 0.6rem !important;
  margin: -0.4rem !important;
  display: flex;

  .icon-simple-add {
    margin-right: 0.4rem;
  }
}