.vrr-sort-container {
  display: flex;

  .vrr-sort-arrow {
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    padding-top: 0.2rem;
  }
}
