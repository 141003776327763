.error-page-container {
  width: 30vh;
  height: 80vh;
  margin: 25vh auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  .error-page-text-container {
    margin-top: 2rem;
    margin-left: 10%;
  }
}
