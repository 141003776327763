@import './../../../../../../../../assets/vrr/scss/styles.scss';

div.cta-modal-header {
  h5.modal-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // spaceship icon & title
    span:first-child {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      align-items: center;
      span:first-child {
        i {
          background-color: $navy-blue-gradient;
          color: $vrr_white;
          padding: 0.5rem;
          border-radius: 50%;
        }
      }
      span:nth-child(2)::first-letter {
        text-transform: uppercase;
      }
    }
    // "+ Add" button
    span:last-child {
      button {
        display: flex;
        padding: 0.5rem 0.9rem 0.5rem 0.8rem;
        margin: -0.4rem;
        gap: 0.9rem;
      }
    }
  }
}
