@import './../../../assets/vrr/scss/styles.scss';

.gdpr-summary {
  background: $vrr-red;
  box-shadow: 0.1rem 0.1rem 0.8rem 0.063rem $vrr-grey_medium;
  margin: 1.25rem 0 1.25rem 1.25rem;
  padding: 2rem 1rem;
  border-radius: 0.313rem;
  text-align: left;
  flex: none;
  width: 25%;

  li {
    list-style: none;
    margin-bottom: 1.5rem;

    a {
      color: $vrr-white;
    }
  }

  .gdpr-section-child {
    font-size: .8rem;
  }
}