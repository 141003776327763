@import '../../../../../assets/vrr/scss/styles';

.form-group {
  .rdt {
    float: left;
    width: 100%;
    input.form-control {
      border-color: $vrr-grey-input;
      font-size: $vrr-small-text;
      line-height: 1.663rem;
      padding-left: 0.688rem;
      border-radius: 0.25rem;
      min-height: 2.563rem;
    }
    .rdtPicker {
      width: max-content;
      .dow,
      .rdtNext,
      .rdtPrev,
      .rdtOld {
        color: $vrr_grey_medium;
      }
      .rdtDay {
        width: 2.25rem;
        color: $vrr_black;
      }
      .rtdDays {
        display: flex;
        color: $vrr_black;
      }
      .rdtActive {
        background-color: $vrr_red !important;
      }
      .rdtSwitch {
        color: $vrr_grey_medium;
      }
    }
  }
}

.simple-date-picker input:focus {
  border-color: $vrr_red !important;
}
