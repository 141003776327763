@import '../../../assets/vrr/scss/styles';

.vrr-dropdown {
  min-width: 15rem;

  .vrr-dropdown__control--is-focused {
    border-color: $vrr_red;
    box-shadow: $vrr_red 0 0 0 0.063rem;

    &:hover {
      border-color: $vrr_red;
    }
  }

  .vrr-dropdown__option--is-selected {
    background-color: $vrr_red !important;
  }

  .vrr-dropdown__option {
    background-color: unset;
  }

  .vrr-dropdown__option:hover {
    background-color: $vrr_red_opacity;
  }

  .vrr-dropdown-multi-option {
    input[type='checkbox'] {
      opacity: 0;
    }

    label.check {
      display: flex;
      gap: 1rem;
      padding-left: 0.5rem;
      position: relative;
      align-items: center;
      pointer-events: none;
    }

    input[type='checkbox'] + label.check span {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      min-width: 0.938rem;
      min-height: 0.938rem;
      background-color: transparent;
      border: solid 0.063rem $vrr_grey_medium;
      border-radius: 0.188rem;
      font-size: 0.7rem;
      line-height: 0.938rem;
    }

    input[type='checkbox']:checked + label.check span::after {
      content: '\2713';
      color: $vrr_white;
      font-weight: bold;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    input[type='checkbox']:checked + label.check span {
      border: none;

      &.cyan-checkbox {
        background-color: $vrr_cyan;
      }
    }
  }
}
