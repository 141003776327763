.content-signin-card {
  margin-top: 3.75rem;
}

.build-footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  margin-left: .5rem;
  margin-bottom: .2rem;
}