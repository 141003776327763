@import '../../../../../assets/vrr/scss/styles.scss';

.tag-table {
  thead > tr {
    top: 0 !important;
    z-index: 20 !important;
    position: sticky !important;
    background-color: $vrr-white;
  }

  .table-header {
    justify-content: center !important;

    &.previous-page-cell {
      justify-content: right !important;
    }

    &.next-page-cell {
      justify-content: left !important;
    }

    .previous-page, .next-page {
      font-size: 4rem;
      margin-bottom: 3rem;
    }
  }

  .table-header:first-child {
    margin: unset;
  }

  .row-tag-title {
    border-top: none !important;
    top: 12rem;
    z-index: 20;
    position: sticky;
    background-color: $vrr-white;
  }

  .vrr-table-row {
    white-space: nowrap;
    border-top: .1rem solid;
  }

  .tag-checkbox {
    margin-left: 50%;

    input[type='checkbox']:checked + label.check span {
      border: none;

      &.color-checkbox {
        background-color:  $vrr_cyan;
      }
    }

    input[type='checkbox'] {
      display: none;
    }

    input[type='checkbox'] + label.check span {
      display: inline-block;
      vertical-align: middle;
      width: 0.938rem;
      height: 0.938rem;
      background-color: transparent;
      border: solid 0.063rem $vrr_grey_medium;
      border-radius: 0.188rem;
      font-size: 0.7rem;
      line-height: 0.938rem;
    }

    input[type='checkbox']:checked + label.check span::after {
      content: '\2713';
      color: $vrr_white;
      font-weight: bold;
      padding-left: .13rem;
    }

    .tag-label {
      width: 18%;
      margin: .5rem auto;

      span {
        cursor: pointer;
        color: #555;

        &.is-disabled {
          pointer-events: none !important;
          border-color: $vrr-grey-input !important;
          color: $vrr-grey-input;
          background: $vrr-grey-medium2 !important;
        }
      }
    }
  }
}

.tag-title {
  font-size: 1rem;
  font-family: 'Megabyte-Bold', serif;
}
