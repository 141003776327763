@import './../../../../../../assets/vrr/scss/styles.scss';

.video-card-header {
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .video-card-header-title {
    display: flex;
    flex-direction: row;
    width: 70%;

    .video-card-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 600;
    }

    .video-card-status {
      display: flex;
      justify-content: center;
      align-items: center;
      height: .4rem;
      width: .4rem;
      background-color: $vrr-cyan;
      border-radius: 5rem;
      margin: .5rem 1rem .5rem .5rem;
    }

    .video-card-status::before {
      content: "";
      height: .4rem;
      width: .4rem;
      border-radius: 5rem;
      padding: .355rem;
      border: .05rem solid $vrr-cyan;
    }
  }

  .video-card-remove {
    padding: .5rem;
  }
}