@import '../../../../assets/vrr/scss/styles.scss';

.table-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  .hide-table-header-sort {
    display: none;
  }
}

.hide-table-header-filter {
  display: none;
}

.table-header:first-child {
  margin-left: .7rem;
}

.fix-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
}

.fix-header[scope=row] {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 1;
}