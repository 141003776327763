@import './../../../../../../../assets/vrr/scss/styles.scss';

.objective-modal {
  &.modal-dialog {
    max-width: 40rem !important;

    i.circle-icon {
      background-color: $navy-blue-gradient;
      color: $vrr_white;
      padding: 0.5rem;
      border-radius: 50%;
      margin-right: 1rem;
    }
    .objective-modal-body {
      width: 80%;
      padding: 2rem 4rem 10rem 2rem;

      .vrr-input-text {
        margin-bottom: 2rem;
      }
    }
  }
}
