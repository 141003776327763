@import "../../../assets/vrr/scss/styles.scss";

.user-profile-menu-container {
  background-color: $vrr_white;
  display: flex;
  flex-direction: column;
  width: 20%;
  height: 100%;
  border-radius: 1.4rem;
  padding: 0.2rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0 0.438rem 1.813rem 0;
  margin-right: 3rem;

  button:not(.selected),
  button:not(.selected):active,
  button:not(.selected):hover,
  button:not(.selected):focus {
    i {
      color: white !important;
    }
    color: white !important;
  }

  button.selected,
  button.selected:active,
  button.selected:hover,
  button.selected:focus {
    background-color: white !important;
  }

  .profile,
  .profile:active,
  .profile:hover,
  .profile:focus {
    color: $vrr_orange !important;
    border-color: $vrr_orange !important;
    background-color: $vrr_orange !important;
  }

  .password,
  .password:active,
  .password:hover,
  .password:focus {
    color: $vrr_cyan !important;
    border-color: $vrr_cyan !important;
    background-color: $vrr_cyan !important;
  }

  .connected-account,
  .connected-account:active,
  .connected-account:hover,
  .connected-account:focus {
    color: $vrr_mauve !important;
    border-color: $vrr_mauve !important;
    background-color: $vrr_mauve !important;
  }

  .label-button {
    i {
      margin-right: 0.6rem;
    }
  }
}
