@import './../../../../../../assets/vrr/scss/styles.scss';

.media-plan-targets-section-container {
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
  .media-plan-targets-section-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @include title-with-icon-media-plan-details;
  }

  .media-plan-targets-section-body {
    display: flex;
    gap: 1.3rem;
    justify-content: space-around;

    &.description-left {
      flex-direction: row;
    }

    &.description-right {
      flex-direction: row-reverse;
    }

    .media-plan-targets-section-body-description {
      display: flex;
      align-items: center;
      font-size: $vrr-small-text;
      vertical-align: middle;
      white-space: break-spaces;
    }

    .media-plan-targets-section-body-values {
      display: grid;
      grid-template-columns: auto auto;
      grid-gap: 0.6rem;
      align-content: center;

      svg {
        width: 1.2rem;
        margin-right: 0.5rem;
      }

      .no-data {
        background-color: $vrr_white;

        &.male {
          border: 0.125rem solid $vrr-orange;
          color: $vrr-orange;

          svg {
            fill: $vrr-orange;
          }
        }

        &.female {
          border: 0.125rem solid $vrr-red;
          color: $vrr-red;

          svg {
            fill: $vrr-red;
          }
        }
      }

      .with-data {
        fill: $vrr_white;

        &.male {
          background-color: $vrr-orange;
        }

        &.female {
          background-color: $vrr-red;
        }
      }

      .media-plan-targets-section-default {
        &.no-data {
          background-color: $vrr_white;
          color: $vrr-mauve;
          border: 0.125rem solid $vrr-mauve;
        }

        &.with-data {
          background-color: $vrr-mauve;
        }
      }

      .media-plan-targets-section-gender {
        padding: 0.3rem 0;
      }

      .media-plan-targets-section-targets {
        padding: 0.2rem 01rem;
        display: flex;
        flex-direction: row;
        gap: 0.6rem;
        align-items: center;
        &.no-data {
          background-color: $vrr_white;

          &.top {
            color: $vrr-orange;
            border: 0.125rem solid $vrr-orange;
          }

          &.middle {
            color: $vrr-mauve;
            border: 0.125rem solid $vrr-mauve;
          }

          &.bottom {
            color: $vrr-red;
            border: 0.125rem solid $vrr-red;
          }
        }

        &.with-data {
          &.top {
            background-color: $vrr-orange;
          }

          &.middle {
            background-color: $vrr-mauve;
          }

          &.bottom {
            background-color: $vrr-red;
          }
        }

        .targets-count {
          font-size: $vrr-highlight-text;
        }
      }

      .media-plan-targets-section-default,
      .media-plan-targets-section-gender,
      .media-plan-targets-section-targets {
        color: $vrr_white;
        min-width: 7.6rem;
        border-radius: 0.7rem;
        font-family: 'Megabyte-Bold', serif;
        text-transform: uppercase;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .media-plan-targets-section-default,
      .media-plan-targets-section-gender {
        text-align: center;
      }

      .media-plan-targets-section-default {
        padding: 0.6rem 1.5rem;
      }
    }
  }
}

.media-plan-targets-section-container:not(:last-child) {
  border-bottom: solid 0.1rem $vrr-grey-input;
  padding-bottom: 1.5rem;
}

// Targets section
.media-plan-targets-section-container:nth-of-type(4) {
  .media-plan-targets-section-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 4%;

    .media-plan-targets-section-body-values {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }
}
