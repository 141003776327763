@import './../../../../../../assets/vrr/scss/styles.scss';

.metric-selection-card-container {
  box-shadow: rgba(0, 0, 0, 0.1) 0 0.25rem 0.75rem !important;

  .metric-selection-card {
    width: 16rem;
    height: 4rem;
    padding: .5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .metric-tooltip-icon {
      width: 1.7rem;
      height: 1.7rem;
    }

    .metric-tooltip-icon:focus {
      outline: none;
    }

    .metric-icon {
      margin: 0 1rem 0 0;
      font-size: 1.3rem;

      &.custom-icon {
        width: 1.6rem;
      }

      &.red {
        fill: $vrr_red !important;
        color: $vrr_red !important;
      }

      &.purple {
        fill: $vrr_mauve !important;
        color: $vrr_mauve !important;
      }

      &.turquoise {
        color: $vrr_cyan !important;
      }

      &.yellow {
        color: $vrr_orange !important;
      }
    }
  }
}
