@import '../../../assets/vrr/scss/styles.scss';

.user-password-container {
  box-shadow: rgba(100, 100, 111, 0.2) 0 0.438rem 1.813rem 0;
  border-radius: 0.4rem;
  padding: 0rem 1rem 1rem 1rem;
  background-color: $vrr_white;
}

.user-password-card-body {
  padding: 0.938rem;
}
