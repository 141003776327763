@import './../../../../../../../../../../assets/vrr/scss/styles.scss';

header.search-section {

  div.vrr-search-input-group {
    width: 100%;

    .vrr-search-text-input {
      height: unset;
    }

    div.input-group {
      margin-bottom: 0 !important;
    }

    div.vrr-search-input-group {
      margin-bottom: 0;
    }

    div.vrr-dropdown {
      min-width: unset !important;

      div.vrr-dropdown__control {
        max-width: unset;
        width: 9rem;
      }
    }
  }
}
