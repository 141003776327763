@import "./../../../../../assets/vrr/scss/styles.scss";

.budget-boost-list-sub-container {
  display: flex;
}

.budget-boost-list-sub-list {
  width: 90%;
}

.budget-boost-list-sub-item-grid {
  width: 10%;
}

.budget-list-main-item {
  display: inline-block;
}

.budget-circle {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: inline-block;
  margin-top: 1rem;
}

.budget-list-main-item {
  display: flex;
  svg {
    position: relative;
    top: 1rem;
  }
}

.circle-total_budget {
  background-color: $vrr_red;
}
.circle-weekly_budget {
  background-color: rgb(251, 145, 30);
}
.circle-available_budget {
  background-color: rgb(36, 215, 192);
}
.circle-spent_budget {
  background-color: rgb(113, 76, 224);
}

.budgetlist-container {
  .list-group-item {
    border: 0;
  }
}
