@import '../../../assets/vrr/scss/styles.scss';

.difference-card-container {
  box-shadow: rgba(0, 0, 0, 0.1) 0 0.25rem 0.75rem !important;
}

.difference-card-container:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0 0.188rem 0.5rem !important;
}

.difference-card-container,
.difference-card-container:hover {
  width: 20rem;
  border-radius: 0.6rem;
  padding: 1rem;

  .difference-card-header {
    height: 15rem;
    border-radius: 0.6rem;

    &.red {
      background-color: $vrr_red;

      .header-content,
      p {
        color: $vrr_white;
      }
    }

    &.orange {
      background-color: $vrr_orange;

      .header-content,
      p {
        color: $vrr_white;
      }
    }

    &.mauve {
      background-color: $vrr_mauve;

      .header-content,
      p {
        color: $vrr_white;
      }
    }

    &.turquoise {
      background-color: $vrr_cyan;

      .header-content,
      p {
        color: $vrr_white;
      }
    }

    &.grey {
      background-color: $vrr_grey_medium2;

      .header-content,
      p {
        color: $vrr_grey_dark;
      }
    }

    .header-content {
      padding: 1.5rem 2rem 0 0;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .header-value {
        font-family: 'Megabyte-Bold', serif;
        font-size: 4.5rem;

        &.up::before {
          content: '\2191';
          color: $vrr_cyan;
        }

        &.down::before {
          content: '\2193';
          color: $vrr_red;
        }

        &.up::before,
        &.down::before {
          font-family: 'megabyte-regular', serif;
          margin-right: 1rem;
          font-size: 4rem;
        }
      }
    }
  }

  .difference-card-middle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    top: -1.3rem;
    position: relative;

    .middle-hide-button {
      padding: 0 0.15rem;
      margin: auto 0;
      height: 2.2rem;
      width: 2.2rem;
    }

    .eye-icon {
      padding: 0.5rem;
    }

    .eye-icon-crossed {
      padding: 0.6rem;
    }

    .eye-icon {
      position: relative;
      top: -0.6rem;
      left: -0.62rem;
    }

    .eye-icon-crossed {
      position: relative;
      top: -1rem;
      left: -1.25rem;
    }

    .send-icon,
    .send-icon-crossed {
      width: 1rem;
      height: 1.5rem;
    }

    .difference-card-middle-left {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .middle-publish-button {
      padding: 0 0.15rem;
      height: 2.2rem;
      width: 2.2rem;
      margin: auto 0 auto 0.5rem;

      i {
        font-size: 1.5rem;
      }
    }

    .middle-tags,
    .middle-tags:hover,
    .middle-tags:active {
      font-weight: 500;
      padding: 0.5rem 2rem;
      cursor: inherit;
      box-shadow: none;
      transform: none !important;
      margin: auto 0 auto 0.5rem;
      text-overflow: ellipsis;
      white-space: nowrap;

      &.red {
        background: $vrr_red !important;
      }

      &.orange {
        background: $vrr_orange !important;
      }

      &.mauve {
        background: $vrr_mauve !important;
      }

      &.turquoise {
        background: $vrr_cyan !important;
      }

      &.grey {
        background: $vrr_grey_medium2 !important;
      }
    }
  }
}
