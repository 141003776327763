@import './../../../../../../assets/vrr/scss/styles.scss';

.card-title {
  .metric-card-title {
    .metric-card-kpi-custom-icon {
      margin: -1.5rem -0.8rem auto -0.5rem;
    }

    .metric-card-dimension {
      width: 1.5rem;
    }

    .metric-card-kpi-custom-icon,
    .metric-card-dimension {
      &.red {
        fill: $vrr_red !important;
      }

      &.purple {
        fill: $vrr_mauve !important;
      }

      &.turquoise {
        fill: $vrr_cyan !important;
      }

      &.yellow {
        fill: $vrr_orange !important;
      }
    }

    .metric-card-kpi-icon {
      font-size: large;
      &.red {
        color: $vrr_red !important;
      }

      &.purple {
        color: $vrr_mauve !important;
      }

      &.turquoise {
        color: $vrr_cyan !important;
      }

      &.yellow {
        color: $vrr_orange !important;
      }
    }
  }
}
.card-text {
  .circle-loader-active {
    padding-bottom: 2rem;
  }
}
