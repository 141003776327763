.metric-detail-pin {
  margin-bottom: 1rem;
  max-height: 40vh;
  overflow-y: auto;

  .metric-detail-header-title {
    &.modal-header {
      border: unset;
    }
  }

  .card-body {
    padding: 0 1rem;
  }
}
