@import './../../../../../assets/vrr/scss/styles.scss';

table.metric-table {
  table-layout: fixed;
  width: 100%;
  thead {
    tr {
      th {
        border: none;
        div {
          div:first-child {
            margin-left: 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            p {
              text-transform: uppercase;
              color: $vrr_navy;
              font-family: 'Megabyte-Bold';
              margin-bottom: 0;
            }
            span {
              svg {
                filter: $vrr_white_svg_filter !important;
              }
            }
          }
        }
      }
      th:first-child {
        padding: 0;
      }
      th:last-child {
        padding-left: 0;
      }
    }
  }
  tbody {
    tr {
      td {
        p {
          color: $vrr_navy;
          vertical-align: baseline !important;
          font-family: 'megabyte-regular';
          margin-bottom: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      td:first-child {
        padding: 1.5rem 1rem 1.5rem 0;
      }
      td:last-child {
        padding-left: 0;
      }
      td:first-child:has(img) {
        padding: 0.8rem 1rem 0.8rem 0;
      }
    }
  }
}
