@import './../../../../../assets/vrr/scss/styles.scss';

.metric-detail-header {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0.5rem 0.5rem 1rem;
  align-items: center;

  .metric-detail-header-title {
    flex-shrink: unset;
    padding: unset;
  }
  .metric-detail-header-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    .close-action,
    .pin-action {
      margin: 0 0.5rem;
      padding: 0.5rem !important;
    }
  }

  .metric-icon {
    margin: 0 1rem 0 1rem;
    font-size: 1.3rem;

    &.custom-icon {
      width: 1.8rem;
    }

    &.red {
      fill: $vrr_red !important;
      color: $vrr_red !important;
    }

    &.purple {
      fill: $vrr_mauve !important;
      color: $vrr_mauve !important;
    }

    &.turquoise {
      color: $vrr_cyan !important;
    }

    &.yellow {
      color: $vrr_orange !important;
    }
  }
}
