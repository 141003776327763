@import './../../../../../assets/vrr/scss/styles.scss';

.percent-metric-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;

  .percent-metric-icon {
    position: relative;
    left: 1.5rem;
    min-height: 3rem;
    height: 3rem;
    min-width: 3rem;
    width: 3rem;
    border-radius: .9rem;
    -ms-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -o-transform: rotate(45deg);

    &.red {
      background-color: $vrr-red;
    }

    &.orange {
      background-color: $vrr-orange;
    }

    &.cyan {
      background-color: $vrr-cyan;
    }

    &.grey {
      background-color: $vrr-grey-card;
    }

    svg {
      position: relative;
      font-size: 2.5rem;
      color: $vrr-white;
      bottom: -.4rem;
      -ms-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
    }
  }

  .metric-section {
    width: 20rem;
    background-color: $vrr-grey-background;
    padding: .8rem 2rem .8rem 3rem;
    border-radius: .5rem;

    .metric-content {
      align-items: center;
      display: flex;
      flex-direction: row;
      row-gap: 1rem;

      .metric-percent, .metric-text {
        font-weight: bold;
      }

      .metric-percent {
        margin-right: 1rem;
        font-size: medium;
      }

      .section-right {
        text-align: left;
      }
    }
  }
}