@import './../../../../assets/vrr/scss/styles.scss';

.media-plan-selection {
  width: 50%;

  .media-plan-selection-body {
    background-color: $vrr_white;
    border-radius: 0.8rem 0.8rem 0 0;
    padding: 1.5rem 1.5rem 2rem 1.5rem;
    gap: 2rem;
    display: flex;
    flex-direction: column;

    .media-plan-selection-label {
      width: 50%;
      color: $vrr-grey-card;

      hr {
        margin-top: 0;
      }
    }
  }
}