@import "../../assets/vrr/scss/styles";

$height-btn: 3.125rem;
$padding-top: 0.938rem;

.vrr-login-card.card-body {
  padding: 0;
}

.vrr-login-footer {
  .login-gdpr {
    font-size: x-small;
    text-align: center;

    a {
      color: $vrr-red;
    }
  }
}

.vrr-login-footer.card-footer {
  padding: 0 0.938rem !important;
}

.btn-vrr-login {
  background: none !important;
  height: $height-btn;
  padding-top: $padding-top;

  &.btn-primary:hover {
    background: $vrr_red !important;
  }

  &.btn-primary:not(:hover) {
    background: $vrr_red !important;
  }
}

.btn-simple {
  &.btn-vrr-login {
    border-color: $vrr_red;
    border-width: 0.125rem;
    color: $vrr_red;
    margin-left: 0;
    height: $height-btn;
    padding-top: $padding-top;
  }
}

.background-compo {
  display: flex;
  padding: 0;
  position: absolute;

  #img-red {
    position: relative;
    width: 90%;
  }

  #img-title {
    position: absolute;
    margin-top: 3.44rem;
    width: 90%;
    padding-left: 3.12rem;
  }
}

.inside-card-body {
  padding: 0.938rem;
  margin-top: 9.375rem;

  .input-group-text {
    border-radius: .4285rem 0 0 .4285rem;
    background-color: $vrr-white;
    padding: 0.5rem !important;
  }

  .form-control {
    border-color: $vrr-grey-input !important;
  }
}

.vrr-options {
  display: flex;
  flex-direction: row-reverse;

  & a {
    text-decoration: underline;
    font-size: x-small;
  }

  & label input {
    margin-top: 0.07rem;
  }

  & .vrr-options_forgot-password {
    color: $vrr-red;
  }
}

.form-group {
  a {
    flex: auto;
  }
}

.btn-request-demo {
  padding-top: 0.875rem !important;
}
