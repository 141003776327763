@import './../../../assets/vrr/scss/styles.scss';

.publish-tabs-component {
  z-index: 0;
  top: -3.5rem;
  left: 2.3rem;

  .inactive {
    background: $vrr_white !important;
    color: $vrr_grey_dark;
    font-weight: 400;
    border: solid 0.1rem $vrr_grey_dark;
  }

  .active {
    transform: none !important;
  }
}
