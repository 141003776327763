@import "./../../../../../assets/vrr/scss/styles.scss";

.notification-item, .notification-item-read {
  border-radius: .8rem;
  margin-bottom: .5rem;

  .notification-optimization,
  .notification-user_action,
  .notification-refresh,
  .notification-setup {
    font-size: 2rem;
  }

  .vrr-button {
    .btn, .btn:hover {
      display: flex;
      flex-direction: row;
      text-align: left;
      background: unset !important;
      color: unset;
      margin: unset;
      line-height: unset;
      padding: .05rem;
      grid-gap: 2rem;
      width: 100%;
      box-shadow: unset;
      align-items: center;
    }

    .notification-status {
      width: .6rem;
      height: .6rem;
      border-radius: 0.8em;
      background-color: $vrr_white;
    }

    .notification-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .notification-text {
        display: flex;
        flex-direction: row;
        grid-gap: 2.2rem;
        align-items: center;

        .notification-link {
          color: unset !important;
          font-weight: 600 !important;
          text-decoration: underline !important;
        }
        span {
          font-size: .7rem;
        }
      }

      .notification-name {
        width: 13rem;
        overflow: hidden;
      }
    }
  }
}

.notification-item {
  &.notification-optimization {
    border: .2rem solid $vrr_cyan;
    color: $vrr_cyan;
  }

  &.notification-user_action {
    border: .2rem solid $vrr_orange;
    color: $vrr_orange;
  }

  &.notification-refresh {
    border: .2rem solid $vrr_mauve;
    color: $vrr_mauve;
  }

  &.notification-platform {
    border: .2rem solid $vrr_grey-input;
    color: $vrr_grey-input;
  }

  &.notification-setup {
    border: .2rem solid $vrr-navy;
    color: $vrr-navy;
  }
}

.notification-item-read {
  color: $vrr-white;

  &.notification-optimization {
    border: .2rem solid $vrr_cyan;
    background-color: $vrr_cyan;
  }

  &.notification-user_action {
    border: .2rem solid $vrr_orange;
    background-color: $vrr_orange;
  }

  &.notification-platform {
    border: .2rem solid $vrr_grey-input;
    background-color: $vrr_grey-input;
  }

  &.notification-refresh {
    border: .2rem solid $vrr_mauve;
    background-color: $vrr_mauve;
  }

  &.notification-setup {
    border: .2rem solid $vrr-navy;
    background-color: $vrr-navy;
  }
}