@import './../../../../../../../../assets/vrr/scss/styles.scss';

.pixel-body-action {
  .pixel-actions {
    padding: 1rem 0;

    .vrr-dropdown {
      min-width: 12rem;
      max-width: 12rem;
    }

    .pixel-dropdown {
      .vrr-dropdown {
        border-bottom: .1rem solid;
      }

      .vrr-dropdown__control {
        border-color: unset;
        border-radius: unset;
        border-style: unset;
        box-shadow: none;
        background-color: unset;

        .vrr-dropdown__indicator-separator {
          display: none;
        }
      }
    }

    .final-action, .other-action {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: .4rem;
    }

    .final-action {
      background-color: rgba(70, 71, 103, .1);
      border-radius: .5rem;
      text-wrap: nowrap;
      span {
        margin: 0 .3rem;
      }
    }

    .other-action {
      .other-action-first {
        opacity: 0;
      }
      .other-action-second {
        width: 4.5rem;
        text-align: center;
      }
      span {
        margin: 0 .3rem;
      }
    }
  }
}
