@mixin normal-size-icon-button() {
  padding: 0.938rem;
}

.vrr-button {
  button {
    margin: 0;
    &.btn-with-icon-and-label {
      @include normal-size-icon-button();
      display: flex;
      justify-content: space-between;
      gap: .6rem;
      align-items: center;
    }
    &.btn-space-around {
      justify-content: space-around;
    }
    &.icon-btn {
      padding: 0.6rem;
      border-radius: 50%;
    }
    &.btn-icon-small-xs {
      padding: 0.5rem;
      width: 5.5rem;
    }
    &.btn-icon-small {
      padding: 0.625rem;
      width: 6.25rem;
    }
    &.btn-icon-normal {
      @include normal-size-icon-button();
    }
    &.btn-icon-large {
      padding: 1.25rem;
      width: 12.5rem;
    }
    &.icon-left {
      flex-direction: row;
    }
    &.icon-right {
      flex-direction: row-reverse;
    }
    &.icon-top {
      flex-direction: column;
    }
    &.icon-bottom {
      flex-direction: column-reverse;
    }
  }
}
