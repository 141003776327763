.global-metrics-selections {
  padding: 2rem 4rem;

  .vrr-selections-container {
    .vrr-selections-line:first-of-type {
    .vrr-selections-item:first-child {
      button {
        display: none;
      }
    }
      }
  }
}