@import './../../../../../../assets/vrr/scss/styles.scss';

table.metric-detail-table {
  thead {
    tr {
      th {
        background-color: $vrr_white;
        position: sticky;
        top: 0;
        z-index: 1;
        div {
          div:first-child {
            margin-left: 0;
            display: flex;
            flex-direction: row;
            gap: 0.3rem;
            align-items: center;
            p {
              text-transform: uppercase;
              color: $vrr_navy;
              font-family: 'Megabyte-Bold';
              white-space: nowrap;
              cursor: pointer;
              margin-bottom: 0;
              span {
                color: $vrr_red;
              }
            }
            span {
              svg {
                filter: $vrr_white_svg_filter !important;
              }
            }
          }
          div > svg {
            stroke: $vrr_grey_medium;
            fill: $vrr_grey_medium;
          }
          div:nth-child(2) {
            div {
              @include input-on-edit-mode-in-modal($vrr_grey_medium);
            }
          }
        }
      }
      th:first-child {
        z-index: 4;
        position: sticky;
        left: 0;
        background-color: $vrr_white;
      }
    }
  }
  tbody {
    tr {
      td {
        p {
          color: $vrr_navy;
          vertical-align: baseline !important;
          font-family: 'megabyte-regular';
        }
      }
      td:first-child {
        z-index: 3;
        position: sticky;
        left: 0;
        background-color: $vrr_white;
      }
    }
  }
}
