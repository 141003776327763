@import '../../../assets/vrr/scss/styles.scss';

.user-general-informations {
  box-shadow: rgba(100, 100, 111, 0.2) 0 0.438rem 1.813rem 0;
  border-radius: 0.4rem;
  padding: 0rem 0.2rem 1rem 1rem;
  background-color: $vrr_white;

  .user-general-infos-main-row {
    padding: 0.625rem;
    display: flex;
    justify-content: space-between;

    .user-general-infos-title {
      margin: 0;
      line-height: 3;
    }
  }

  .edit-switch {
    width: 2rem;
    height: 2rem;
    padding: 0;
    border-radius: 50%;
  }
}

.user-general-infos-container {
  .user-general-infos-row,
  .user-general-infos-button {
    padding: 0 0.625rem;
  }
  .user-general-infos-button {
    padding-bottom: 0.625rem;
    text-align: right;
  }
}

.has-danger::after {
  right: 0;
}
