@import './../../../../../../../../../../../../assets/vrr/scss/styles.scss';

.audience-category-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;
  padding: 0.4rem 0;
  .parent-audience-category {
    margin-bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    span.audience-category-line {
      flex-basis: 12rem;
      max-width: 12rem;
      label.check {
        display: flex;
        flex-direction: row;
        gap: 3rem;
        align-items: center;
      }
    }
    span.subcategory-count {
      font-family: 'Megabyte-Medium';
      color: $vrr_grey_medium;
      text-align: center;
    }
    span:last-child {
      button {
        color: $vrr_grey_medium;
        font-family: 'Megabyte-Medium';
      }
    }
  }
  .subcategories-section {
    p.wrapper-category-line {
      margin-bottom: 0;
      span {
        margin: 0.5rem 0;
        label.check {
          padding-left: 1.5rem;
          display: flex;
          gap: 1.5rem;
          align-items: center;
        }
      }
    }
  }
}
