@import './../../../../assets/vrr/scss/styles.scss';

.information-section {
  display: flex;
  flex-direction: column;
  gap: .5rem;

  .information-section-brand {
    display: flex;
    flex-direction: column;

    .information-brand-name {
      color: $vrr-navy;
      text-transform: uppercase;
      font-weight: 600;
    }

    .information-brand-info {
      display: flex;
      flex-direction: row;

      .information-brand-vertical {
        background-color: $vrr-mauve;
        color: $vrr-white;
        padding: .2rem 1.5rem;
        border-radius: 5rem;
        margin-right: 3rem;
      }
    }
  }

  .information-section-brand, .information-section-video {
    background-color: $vrr-grey-background;
    padding: 1rem;
    border-radius: .3rem;
  }

  .information-section-video {
    .information-video-name {
      font-weight: 600;
      color: $vrr-navy;
    }

    .information-video-info {
      .information-video-column {
        float: left;
        width: 33.33%;
        text-align: center;
        align-items: center;

        p {
          color: $vrr-grey-card;
        }

        .information-video-device {
          svg {
            stroke: $vrr-grey-card !important;
            fill: $vrr-grey-card !important;

            &.is-active {
              stroke: $vrr_navy !important;
              fill: $vrr_navy !important;
            }
          }
        }
      }

      .information-video-column:not(.information-video-column:last-child) {
        border-right: solid .01rem $vrr-navy;
      }

      .information-video-platform {
        font-weight: bold;
        &.youtube {
          color: $vrr-red;
        }

        &.meta {
          color: #0081FB;
        }

        &.instagram {
          color: #E1306C;
        }

        &.tiktok {
          color: $vrr_black_original;
        }
      }

      .information-video-objective {

        &.performance {
          background-color: $vrr-red;
        }

        &.branding {
          background-color: $vrr_navy;
        }

        color: $vrr-white;
        padding: .4rem 1.5rem;
        border-radius: 5rem;
      }
    }
  }
}

