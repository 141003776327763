@import './../../../../../../../assets/vrr/scss/styles.scss';

.optimization-modal {
  &.modal-dialog {
    max-width: 51rem !important;

    i.circle-icon {
      background-color: $navy-blue-gradient;
      color: $vrr_white;
      padding: 0.5rem;
      border-radius: 50%;
      margin-right: 1rem;
    }

    .opti-body-modal {
      padding: 2rem 4rem 3rem 4rem;
    }
  }
}

