div.adwords-account-list {
  margin: 1rem;
  div.col {
    margin: auto;
  }
}

.adwords-account-list button {
  font-size: 0.7rem;
  white-space: nowrap;
  margin: 0rem 1rem 0rem 1rem;
}

.adwords-account-list-table th input {
  margin-left: 0 !important;
}
