@import './../../../../../../../../../assets/vrr/scss/styles.scss';

.funnel-search-list-section {
  width: 25rem;
  padding: 1rem;
  background-color: $vrr_mauve;
  border-radius: .5rem;
  display: flex;
  flex-direction: column;
  gap: calc(1rem - 0.625rem);
}
