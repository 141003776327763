@import './../../../assets/vrr/scss/styles.scss';

div {
  .progress {
    min-width: 15rem;
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: 0.75rem;
    background-color: $vrr_grey_medium2;
    box-sizing: border-box;
    box-shadow: none;
    .progress-bar {
      &.red {
        background-color: $vrr_red;
      }
      &.mauve {
        background-color: $vrr_mauve;
      }
      &.orange {
        background-color: $vrr_orange;
      }
      &.cyan {
        background-color: $vrr_cyan;
      }
    }
  }
}
