.list-bloc {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.icon-media-plan {
  background: url(../../../../assets/vrr/images/media_plan_logo.svg) no-repeat;
  margin-left: 0.45rem;
  margin-right: 0.45rem !important;
  width: 2rem;
  height: 2rem;
}

.sub-item-list {
  display: flex;
  margin-top: 0% !important;
}

.sub-item-list-paragraph {
  margin-left: 2rem !important;
}

.sidebar-sublist {
  display: flex;
  flex-direction: row;
}
