@import './../../../assets/vrr/scss/styles.scss';

.progress-bar {
  transition: none;
}

.progress-bar-container {
  position: relative;
  z-index: 0;
}

.progress-bar-component {
  height: 100%;
  padding-top: 30%;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 45%;
  position: absolute;
  width: 100%;

  span {
    z-index: 10;
  }

  div {
    z-index: 10;
  }

  .progress-bar-component-content {
    width: 100%;
    height: 3rem;
    background-color: white;
    position: relative;

    .progress,
    span:not(.cy-progress-total-pages-indicator) {
      margin-left: 1rem;
      margin-right: 1rem;
    }

    .progress-bar-component-content-number {
      float: right;
    }
  }
}

.progress-bar-component::before {
  content: ' ';
  width: 100%;
  height: 100%;
  top: 0;
  background: radial-gradient(rgba($vrr_black_original, 0.25) 80%, transparent);
  position: absolute;
  left: 0;
  display: revert;
  z-index: 1;
}