@import './../../../assets/vrr/scss/styles.scss';

.vrr-gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 22rem);
  grid-gap: 1rem;
  justify-content: space-between;
}

.no-items {
  text-align: center;
  margin-top: 10rem;
}
