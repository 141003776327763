@import './../../../../../assets/vrr/scss/styles.scss';

.media-plan-card-footer {
  background-color: $vrr_grey_medium2;
  border-radius: 0 0 0.2857rem 0.2857rem;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-content: center;
  padding: 1rem 0;
  font-weight: bolder;
  min-height: 7rem;

  span {
    display: flex;
    gap: 0.7rem;
    max-width: 33%;
    overflow: hidden;
    flex-wrap: wrap;
    align-items: flex-start;

    i {
      max-height: 1.5rem;
      border-radius: 5rem;
      border: 0.1rem solid $vrr_navy;
      padding: 0.2rem;
      color: $vrr_navy;
    }

    .media-plan-card-footer-insight-wrapper {
      flex-direction: column;
      max-width: 70%;
      p {
        flex-wrap: nowrap;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.5rem;
        margin-bottom: 0;
      }
    }
  }
}
