@import './../../../../../../assets/vrr/scss/styles.scss';

div.metric-card {
  background-color: $vrr_white !important;
  height: 100% !important;
  border-radius: $vrr-card-multi-radius;
  box-shadow: none !important;
  max-width: none;
  padding: 0.938rem;
  max-height: 50rem;
  overflow-y: auto;

  div:first-child {
    h5 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0 !important;
      span:first-child {
        padding-top: 0rem;
        display: flex;
        gap: 0.5rem;
        align-items: center;
        i {
          padding-left: 0;
        }
      }
    }
    div:nth-child(2) {
      min-height: 1.5rem;
    }
  }
}
