@import './../../../../../assets/vrr/scss/styles.scss';

.budget-dates {
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  .budget-dates-label {
    color: $vrr-grey-medium;

    hr {
      margin-top: .1rem;
    }
  }

  .budget-date {
    input {
      border: solid .125rem $vrr-grey-medium !important;
    }
  }

  .rdt {
    .white-content .form-control {
      color: $vrr_black;
      border-color: transparent !important;
    }

    .rdtPicker {
      display: block;

      .rdtNext {
        display: table-cell;
      }

      .dow,
      .rdtNext,
      .rdtPrev,
      .rdtSwitch,
      .rdtOld {
        color: $vrr_grey_medium !important;
      }

      .rdtDay {
        color: $vrr_black !important;

        &.rdtDisabled, &.rdtDisabled:hover {
          background: none;
          color: $vrr-grey-medium !important;
          cursor: not-allowed;
        }
      }

      .rdtDay.rdtActive {
        color: $vrr-white !important;
      }

      .rdtMonth.rdtActive {
        background-color: $vrr_red !important;
      }

      .rdtYear.rdtActive {
        background-color: $vrr_red !important;
      }
    }

    tbody .rdtDay.rdtActive.rdtActive {
      background-color: $vrr_red !important;
    }
  }
}