@import './../../../assets/vrr/scss/styles.scss';

.btn-right-bar-container {
  position: relative;
  .btn-right-bar,
  .btn-right-bar:hover,
  .btn-right-bar:active,
  .btn-right-bar:focus {
    width: 3rem;
    padding: 3.5rem 0.9rem 1.5rem 0.9rem;
    color: $vrr_red;
    background-color: $vrr_white;
    border: 0.2rem solid $vrr_red !important;
    border-radius: 0.5rem 0 0 0.5rem !important;
    border-right: none !important;

    .btn-right-bar-label {
      transform: rotate(0.75turn);
    }
  }
}

.right-bar {
  padding-top: 9.75rem;
}

.boost-button,
.boost-button:active,
.boost-button:focus {
  background-color: $vrr_red !important;
  color: $vrr_white !important;
  &:hover {
    color: $vrr_red !important;
  }
}
