@import './../../../../assets/vrr/scss/styles.scss';

h4.section-title {
  display: flex;
  flex-direction: row;
  gap: 0.85rem;
  margin-bottom: 0;
  align-items: center;
  i {
    background-color: $navy-blue-gradient;
    color: $vrr_white;
    padding: 0.5rem;
    border-radius: 50%;
  }
  span {
    color: $navy-blue-gradient-opacity;
    font-size: $vrr-normal-size-text;
  }
  span::first-letter {
    text-transform: uppercase;
  }
}
