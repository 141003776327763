@import '../../../../assets/vrr/scss/styles';

.launcher-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  background-color: $vrr_grey_medium2;
  border-radius: 0 0 0.8rem 0.8rem;
  padding: .7rem !important;
  width: 100%;
  gap: .5rem;
  margin-bottom: 1rem;

  span {
    button {
      margin: .5rem;
      padding: .5rem 1.2rem !important;
    }
  }

  :last-child {
    button {
      background-color: $vrr-navy !important;
      background-image: unset !important;
    }
  }
}
