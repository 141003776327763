@import '../../../../../assets/vrr/scss/styles';

$radius: 0.6rem;
$border-radius-card: $radius $radius 0 0;

.card-stats-selected:hover {
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.16) 0 0.063rem 0.25rem !important;
}

.card-stats {
  .card-body {
    padding: 0 !important;
  }
  &.global-metrics,
  &.add-metrics {
    margin: 0;
  }

  border-radius: $border-radius-card;
  display: block;

  .small-add-icon {
    position: absolute;
    float: right;
    transform: scale(0.5);
    top: -1.3rem;
    right: -1.1rem;
  }

  .body-card {
    height: 100%;

    .body-metric-card {
      padding: 0.5rem 0.938rem 0.938rem 2rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      section.metric-value {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 0.5rem;
        align-items: flex-end;
        h3 {
          margin: 0 !important;
        }
      }
    }

    .card-footer {
      position: absolute;
      bottom: 0;
      width: 100%;
    }

    .card-progress {
      &.up {
        color: $vrr_cyan;
      }

      &.down {
        color: $vrr_red;
      }
    }
  }
}

.add-icon-root {
  height: 100%;

  .card-body {
    height: 100%;
  }
}

.stats {
  &.right-align {
    float: right;
  }

  &.font-for-stats {
    font-size: 0.7rem;
    text-align: left;

    &.to-right {
      text-align: right;
    }

    .displayer {
      display: inline;
    }
  }
}

.top-border-card {
  border-radius: $border-radius-card;
  height: 0.38rem;

  &.red {
    background-color: $vrr_red;
  }

  &.yellow {
    background-color: $vrr_orange;
  }

  &.mauve {
    background-color: $vrr_mauve;
  }

  &.turquoise {
    background-color: $vrr_cyan;
  }

  &.grey {
    background-color: $vrr_grey_medium;
  }
}

.info-icon {
  border-radius: $border-radius-card;
  height: 0.38rem;
  margin: 0.7rem 0.9rem;

  &.red {
    background: $vrr_red !important;
  }

  &.yellow {
    background: $vrr_orange !important;
  }

  &.mauve {
    background: $vrr_mauve !important;
  }

  &.turquoise {
    background: $vrr_cyan !important;
  }

  &.grey {
    background: $vrr_grey_medium !important;
  }
}

.gm-icon {
  &.red {
    fill: $vrr_red !important;
  }

  &.yellow {
    fill: $vrr_orange !important;
  }

  &.mauve {
    fill: $vrr_mauve !important;
  }

  &.turquoise {
    fill: $vrr_cyan !important;
  }

  &.grey {
    fill: $vrr_grey_medium !important;
  }
}

.add-icon-position {
  height: 100%;
  position: relative;

  & .center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }
}

.add-icon {
  zoom: 2;
  cursor: pointer;
}
