@import './../../../assets/vrr/scss/styles.scss';

.publish-modal-header {
  display: flex;
  flex-direction: row;
  padding: 1rem 2rem 1rem 2rem;

  .header-icon,
  .header-icon:hover,
  .header-icon:active {
    margin-right: 2rem;
    cursor: inherit;
    box-shadow: none !important;
    transform: none !important;
  }

  .header-title {
    line-height: 3;
    margin: 0 !important;
  }
}

.publish-modal-body {
  padding: 0 2rem 1rem 2rem;

  .difference-title {
    color: $vrr_grey_medium;
    font-family: 'Megabyte-Bold';
    margin-bottom: 1rem;
  }
}

.publish-modal-footer {
  display: flex;
  justify-content: flex-end;
  background-color: $vrr_grey_medium2;
  padding: 1rem 2rem 1rem 2rem;
}
