@import './../../../assets/vrr/scss/styles.scss';

.modal-share-title,
.modal-share-form-input {
  padding: 0 2rem 0 2rem;
}

.modal-share-title {
  padding-top: 2rem;
}

.modal-share-form-input {
  padding-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.5rem;

  .viewer-list {
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
  }
}

.modal-share-footer {
  display: flex;
  justify-content: space-between;
  background-color: $vrr_grey_medium2;
  padding: 1rem 2rem 1rem 2rem;
}

.share-options {
  display: flex;
  align-items: center;
  margin-left: 1.3rem;
}
