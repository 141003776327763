@import './../../../assets/vrr/scss/styles.scss';

.launcher-pipeline {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: $vrr_white;
  border-radius: $vrr-border-radius-sm;
  margin-bottom: 1rem;

  .status {
    padding: 0 1.5rem 1.5rem 0;
    display: flex;
    margin: auto;
    .launcher-step {
      background-color: $vrr-grey-background;
      border-radius: 5rem;
      width: 3rem;
      height: 3rem;
      text-align: center;
      align-content: center;
      &.is-active {
        background-color: $vrr-red;
      }
      .circle-icon {
        color: $vrr-red;
        font-size: 1.3rem;
        top: .7rem;
        &.is-active {
          color: $vrr-white;
        }
      }

      .launcher-label, i {
        position: relative;
      }

      .launcher-label {
        top: 2rem;
        text-wrap: nowrap;
        left: -1rem;
        font-size: .8rem;
      }
    }
    .step-bar {
      margin-top: 1.35rem;
      background-color: $vrr-grey-background;
      width: 15rem;
      height: .3rem;
      &.is-active {
        background-color: $vrr-red;
      }
    }
  }

  .step-label {
    display: ruby;
    margin: auto;
  }

  .launcher-action {
    background-color: $vrr-grey-medium2;
    padding: 2.5rem 3rem;
    border-radius: 0 $vrr-border-radius-sm $vrr-border-radius-sm 0;
  }
}