@import './../../../../../../assets/vrr/scss/styles.scss';

@mixin outline-footer-style($size, $color) {
  color: $color;
  border: solid $size $color;
}

.media-plan-assets-card {
  padding: 1.5rem;
  border-radius: $vrr-border-radius-sm;
  min-height: 20rem;
  .asset-text {
    color: $navy-blue-gradient;
  }
  @include title-with-icon-media-plan-details;
  .card-body {
    display: flex;
    align-items: center;
    .card-text {
      text-align: center;
      font-size: $vrr-small-text;
    }
  }
  .media-plan-assets-card-footer {
    span:not(.tim-icons.icon-pencil) {
      font-family: 'megabyte-Bold';
    }
    position: relative;
    span.vrr-button {
      position: absolute;
      top: 0;
      right: 0;
      translate: 1rem -1rem;
    }
    &.classic-asset {
      border-radius: $vrr-border-radius-sm;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1.8rem;
      span.asset-count {
        font-size: $vrr-highlight-text;
      }
      span.asset-name {
        text-transform: uppercase;
      }
      &.interior {
        padding: 0.75rem 1.5rem;
        span {
          color: $vrr_white;
        }
        &.footer-orange {
          background-color: $vrr_orange;
        }
        &.footer-red {
          background-color: $vrr_red;
        }
        &.footer-purple {
          background-color: $vrr_mauve;
        }
        &.footer-turquoise {
          background-color: $vrr_cyan;
        }
      }
      &.outline {
        padding: 0.64rem 1.25rem;
        &.footer-orange {
          @include outline-footer-style($vrr-border-width-button, $vrr_orange);
        }
        &.footer-turquoise {
          @include outline-footer-style($vrr-border-width-button, $vrr_cyan);
        }
      }
    }
  }
}
