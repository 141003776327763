@import '../../../../../../assets/vrr/scss/styles.scss';

.modal-boost-dates {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 3rem;

  .modal-boost-dates-start {
    flex-grow: 1;
  }

  .modal-boost-dates-end {
    flex-grow: 1;
  }

  .modal-boost-split-test {
    flex-basis: 10rem;
    flex-grow: 2;
  }

  p.modal-boost-dates-text,
  p.modal-boost-split-test-text {
    color: $vrr_grey_medium;
    &.disabled {
      border-bottom: solid 0.2rem $vrr_grey_medium2;
    }
  }

  div.simple-date-picker {
    input[type='text']:disabled {
      background-color: $vrr_white;
      border: none;
      color: $vrr_grey_medium;
      padding-left: 0;
      font-size: $vrr-normal-size-text;
    }
  }
}
