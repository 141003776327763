@import '../../../../assets/vrr/scss/styles';

.deck-metrics {
  .loader-gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, 22rem);
    grid-gap: 1rem;
    row-gap: 1rem;
    justify-content: space-between;

    .loader-gallery-card {
      height: 10rem;
    }
  }
}
