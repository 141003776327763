@import './../../../../../../../../assets/vrr/scss/styles.scss';

.vrr-modal-header {
  .optimization-actions {
    width: 22%;
    .optimization-modal-header-clear {
      width: max-content;
      right: unset !important;
      top: unset !important;
    }

    .optimization-modal-header-add, .optimization-modal-header-clear {
      padding: 0.5rem 0.9rem !important;
      margin: -0.4rem !important;
      display: flex;

      .icon-simple-add, .icon-simple-remove {
        margin-right: .7rem;
      }
    }
  }
}