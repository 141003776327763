@import './../../../assets/vrr/scss/styles.scss';

.vrr-input-container {
  .input-error-message {
    color: $vrr_red;
  }

  .no-currency {
    .vrr-input-text:focus {
      border-color: $vrr_grey_dark !important;
    }

    .form-control:first-child {
      border-right: 0.06rem solid;
    }
    .form-control:last-child {
      border-left: 0.06rem solid;
    }
  }

  .with-currency {
    display: flex;
    flex-wrap: nowrap;
    .vrr-input-text:focus {
      border-color: $vrr_grey_medium !important;
    }
    span.vrr-currency-input {
      border-radius: 0.4285rem 0 0 0.4285rem;
      border-right: none;
      border-color: $vrr_grey_medium;
      border-width: $vrr-border-width-button;
    }
  }
  .invalid-feedback {
    display: block !important;
  }
}
