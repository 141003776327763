@import './../../../../../../../assets/vrr/scss/styles.scss';

.video-preview-url {
  display: flex;
  flex-direction: row;
  align-items: center;

  .form-group {
    flex: 1;
    margin: 0;
  }

  .vrr-collapse-button {
    margin-top: 0;
  }
}
