.vrr_breadcrumb-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  .vrr_breadcrumb-item {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }
}

.vrr-breadcrumb-action {
  cursor: pointer;
}
