.metrics-gallery {
  .loader-gallery {
    display: unset;
    .loader-gallery-card {
      height: 18rem;
      width: 100%;
    }
  }

  .card-columns {
    column-count: auto;
    column-width: 17.188rem;

    .vrr-add-card {
      .vrr-add-card-button {
        margin: auto;
        padding: 1.5rem;
      }
    }
  }
}
