.vrr-layout {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .vrr-content {
    flex-grow: 1;
    padding: 2rem 4rem 0 8rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
