@import '../variables';

@mixin creative-video-details-table-header {
  thead {
    tr {
      th {
        padding: 0 !important;
        div {
          div:first-child {
            margin: 0 !important;
            p {
              color: $vrr_grey_medium !important;
              font-family: 'Megabyte-Bold';
            }
          }
          div:last-child {
            div {
              @include input-on-edit-mode-in-modal($vrr_grey_medium);
            }
          }
        }
      }
    }
  }
}

@mixin creative-video-details-table-body {
  tbody {
    tr {
      border-top: solid 0.063rem $vrr_grey_medium2;
      padding-top: 1rem;
      td {
        height: auto;
        border: none !important;
        display: flex;
        align-items: center;
        font-family: 'megabyte-regular';
        p,
        span {
          margin: 0;
          color: $navy-blue-gradient;
        }
      }
    }
  }
}
