@import './../../../../../../../../assets/vrr/scss/styles.scss';

table.cta-table {
  thead {
    tr {
      th {
        div {
          div:first-child {
            display: flex;
            flex-direction: row;
            gap: 0.3rem;

            p {
              color: $vrr_grey_medium;
              font-family: 'Megabyte-Bold';
            }
          }

          div > svg {
            stroke: $vrr_navy;
            fill: $vrr_navy;
          }

          div:nth-child(2) {
            div {
              @include input-on-edit-mode-in-modal($vrr_grey_medium);
            }
          }
        }
      }
    }
  }

  tbody {
    tr {
      td {
        color: $vrr_navy;
        vertical-align: baseline !important;

        div {
          div {
            @include input-on-edit-mode-in-modal($vrr_grey_medium);

            input {
              color: $vrr_navy !important;
            }
          }
        }
      }

      td:first-child,
      td:nth-child(2) {
        font-family: 'megabyte-regular';

        p {
          padding-left: 0.7rem;
        }
      }

      td:nth-child(1n + 3) {
        padding: 0;
      }

      td:nth-child(3) {
        span {
          button {
            padding: 0.5rem;
          }
        }
      }
    }

    tr:has(input) {
      border-top-right-radius: 1.25rem;
      background-color: $vrr_grey_medium2;

      td:first-child {
        border-top-left-radius: 0.625rem;
        border-bottom-left-radius: 0.625rem;
      }

      td:last-child {
        border-top-right-radius: 0.625rem;
        border-bottom-right-radius: 0.625rem;
      }
    }
  }
}
