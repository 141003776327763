@import '../../../../../../../assets/vrr/scss/styles';

.input-url-container {
  display: flex;
  flex-direction: row;
  margin-bottom: .5rem !important;

  .vrr-input-container {
    width: 95%;
  }
}
