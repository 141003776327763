@import './../../../../../../../../assets/vrr/scss/styles.scss';

div.target-body-modal {
  padding: 1rem;
  display: flex;
  flex-direction: row;
  gap: 2rem;

  .target-list-side {
    display: flex;
    flex-direction: column;
    width: 50%;
  }
}
