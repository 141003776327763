@import './../../../assets/vrr/scss/styles.scss';

.vrr_tag {
  border: none;
  padding: 0rem 1rem 0rem 0.8rem;
  text-align: center;
  text-decoration: none;
  border-radius: 1.875rem;
  display: inline-flex;
  align-items: center;
  p:first-child {
    color: white;
    padding-top: 0.675rem;
    padding-left: 0.2rem;
  }
  span {
    padding-left: 0.3rem;
    color: white;
    button {
      padding-right: 0rem;
    }
  }

  &.cyan {
    background-color: $vrr_cyan;
  }

  &.red {
    background-color: $vrr_red;
  }

  &.yellow {
    background-color: $vrr_orange;
  }

  &.mauve {
    background-color: $vrr_mauve;
  }

  &.turquoise {
    background-color: $vrr_cyan;
  }

  &.grey {
    background-color: $vrr_grey_medium;
  }
}
