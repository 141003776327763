@import './../../../../../assets/vrr/scss/mixins';

.boostchart {
  width: 60%;
  height: auto;
  margin: auto;
  padding: 0 !important;
  canvas {
    @include respond-to-attribute(
      margin-bottom,
      1.875rem,
      (
        min: 0rem,
        max: 62rem,
      )
    );
  }
}
