@import './../../../../../assets/vrr/scss/styles.scss';

.media-plan-card {
  width: 22rem;
  cursor: pointer;
}

.media-plan-card:hover {
  box-shadow: $vrr_grey_medium 0 0.125rem 0.5rem 0 !important;
}
