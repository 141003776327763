@import '../../../../../assets/vrr/scss/styles.scss';
@import '../../../../../assets/vrr/scss/variables';

.modal-boost-footer {
  display: flex;
  flex-direction: column;
  background-color: $vrr_grey_medium2;

  .modal-boost-footer-actions {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
  }
}
