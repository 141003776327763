@import './../../assets/vrr/scss/styles.scss';

.dropdown-toggle svg {
  color: $vrr_red;
  font-size: 1.25rem;

  &:hover,
  &:focus {
    color: $vrr_red;
  }
}

.btn-actions.dropdown-toggle {
  height: unset !important;
  padding: 0.6rem 0.9rem 0.5rem 0.9rem !important;
  margin-right: unset !important;
}
