@import './../../../../../../assets/vrr/scss/styles';

.video-cell {
  .video-cell-link {
    width: 13rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .video-cell-img {
      border-radius: 0;
    }

    .video-cell-title {
      position: relative;
      background-color: $vrr-white;
      color: $vrr_navy !important;
      padding: .4rem;
      border-radius: .5rem;
      border: .15rem solid $vrr_navy;
      max-width: 10rem !important;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      top: -1.5rem;
    }
  }

}