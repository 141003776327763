@import './../../../../../../../assets/vrr/scss/styles.scss';

.detail-preview-device {
  .vrr-button-group {
    .active , .inactive{
      border-radius: 5rem !important;
      padding: .3rem .45rem;
      margin-right: 1.3rem;
    }
    .active {
      svg {
        fill: $vrr-white;
        stroke: $vrr-white;
      }
    }
    .inactive {
      svg {
        fill: $vrr_navy;
        stroke: $vrr_navy;
      }
    }
  }
}