@import './../../../../assets/vrr/scss/styles.scss';

.modal-boost-title-container {
  .modal-boost-icon-container {
    .modal-boost-icon {
      transform: rotate(270deg);
    }
  }
}

.modal-boost-body {
  margin-bottom: 3rem;
  padding: 1rem 4rem;
  div.circle-loader {
    div.circle-loader-content {
      display: flex;
      flex-direction: column;
      gap: 3rem;
    }
  }
}

.modal-header {
  width: 100%;
  .modal-boost-icon-container {
    background: $navy-blue-gradient !important;
    border-radius: 50%;
    height: 2rem;
    width: 2rem;
    position: relative;
    top: 0.7rem;
    i {
      position: relative;
      top: 0.5rem;
      right: -0.5rem;
    }
  }
  .boost-underline {
    margin: 0.5rem;
    margin-left: 3rem;
  }
}
.boost-modal-close {
  padding: 0.7rem !important;
  margin: 1px !important;
  i {
    color: $vrr_white;
  }
}

.modal-boost-title-container {
  display: flex;
}

.modal-boost-icon {
  color: $vrr_white;
}

.modal-boost-title {
  text-transform: uppercase;
  color: $navy-blue-gradient !important;
  margin-top: 1rem;
  padding-left: 1rem;
}

.modal-boost-body-container {
  display: flex;
}

.boostchart-container {
  display: flex;
}

.modal-boost-button {
  margin: 0.5rem !important;
}
