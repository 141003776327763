@import './../../../assets/vrr/scss/styles.scss';

.vrr-selections-container {
  p {
    font-size: .8rem;
    margin-bottom: 1.5rem;
  }
  .vrr-selections-line {
    display: inline-grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.5rem;
    margin-bottom: 2rem;

    .vrr-selections-item {
      position: relative;

      .vrr-selections-add, .vrr-selections-delete {
        position: absolute;
        top: -.5rem;
        right: -.5rem;
        margin: 0;
        padding: .24rem;
        border-radius: 5rem;
        font-size: .7rem;
        background-image: unset !important;
      }

      .vrr-selections-add, .vrr-selections-add:hover {
        background-color: $vrr_cyan !important;
        border: .2rem solid rgba(44, 211, 191, .4);
        color: $vrr_white !important;
        -webkit-background-clip: padding-box;
        background-clip: padding-box;
      }

      .vrr-selections-delete {
        background-color: $vrr_red !important;
        border: .2rem solid rgba(255, 0, 0, .4);
        color: $vrr_white !important;
        -webkit-background-clip: padding-box;
        background-clip: padding-box;
      }
    }
  }
}