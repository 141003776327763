@mixin title-with-icon-media-plan-details {
  .title-with-icon {
    display: flex;
    flex-direction: row;
    line-height: 2rem;
    gap: 1rem;
    margin-bottom: 0;
    align-items: center;
    i.circle-icon {
      background-color: $navy-blue-gradient;
      color: $vrr_white;
      padding: 0.5rem;
      border-radius: 50%;
    }
    .title-text {
      font-size: 1rem;
    }
    .title-text::first-letter {
      text-transform: uppercase;
    }
  }
}
