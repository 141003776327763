@import './../../../assets/vrr/scss/styles.scss';

.vrr-add-card {
  .vrr-add-card-button {
    color: $vrr-grey-card;

    .vrr-add-card-icon {
      font-size: 2rem;
    }
  }
}