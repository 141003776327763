@import './../../../../assets/vrr/scss/styles.scss';

.video-scoring-container {
  background-color: $vrr-grey-background;
  padding: 1.5rem 3rem;
  border-radius: .3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .video-scoring-title {
    font-weight: 600;
    color: $vrr-navy;
  }

  .scoring-more {
    margin-top: 4rem;
  }

  svg {
    margin-left: 3rem;
  }
}