.chart-container {
  .loader-gallery {
    display: unset;
    .loader-gallery-card {
      height: 18rem;
      width: 100%;
    }
  }

  .card-chart {
    padding: 1rem;

    .chart-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .select-range-period {
    .vrr-dropdown {
      min-width: 7.5rem;
    }
  }
}
