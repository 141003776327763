@import "../../assets/vrr/scss/styles";

.sidebar::before {
  content: none;
}

.vrr-background {
  background-color: $vrr_red;
  scrollbar-width: none;
  border-radius: 0.4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden !important;
}

.bullet-list {
  position: relative;
  margin: 1rem;
  cursor: pointer;
  transform: translate(0, 0);
}

.bullet-list-open {
  transition: transform 1000ms;
}

.bullet-list-close {
  transition: transform 600ms;
  transform: translate(16.25rem, 0em);
}

@media (min-width: 1199.98px) {
  .sidebar {
    margin: 1rem;
    height: calc(100vh - 30px);
    border-radius: 0.4rem;
  }

  .sidebar:not(:hover) {
    p {
      opacity: 0;
    }
  }

  .bullet-list {
    display: none;
  }
}
