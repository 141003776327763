@import './../../../assets/vrr/scss/styles.scss';

.circle-loader-active {
  display: flex;
  flex-direction: row;
  justify-content: center;
  .spinner-border {
    z-index: 1;

    .visually-hidden {
      opacity: 0;
    }
  }
}

.circle-loader-active::before {
  content: ' ';
  width: 100%;
  height: 100%;
  background-color: rgba($vrr_grey_light, 0.25);
  position: absolute;
  left: 0;
  display: revert;
  z-index: 1;
}
