@import './../../../../../../../../../../../../assets/vrr/scss/styles.scss';

.select-all-categories {
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0 1.7rem 0;
  text-transform: capitalize;
  span.audience-category-line {
    flex-basis: 12rem;
    max-width: 12rem;
    label.check {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      align-items: center;
    }
  }
  span.category-count {
    font-family: 'Megabyte-Medium';
    color: $vrr_grey_medium;
    text-align: center;
  }
  span.right-space {
    width: 2.274rem;
  }
}
