@import '../../../../../assets/vrr/scss/styles';

.video-gallery-card {
  cursor: pointer;
  @include gallery-card;

  img.card-img-top {
    max-height: 11rem;
    border-radius: .5rem .5rem 0 0;
    object-fit: cover;
  }

  .checkbox-card {
    padding: .8rem;
    position: absolute;
    width: 100%;
    top: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @include checkbox-in-galery-card;
  }

  .video-gallery-card-body {
    position: relative;
    min-height: 6rem;
    font-size: .813rem;
    line-height: .813rem;

    .creative-video-detail-button {
      position: relative;
      padding: 0 .5rem;
      top: -3.3rem;

      &.non-authorized {
        visibility: hidden;
      }

      svg {
        width: 1.5rem;
      }
    }

    .about-video {
      display: flex;
      flex-direction: column;
      position: relative;
      top: -2.5rem;
      padding-left: .1rem;
      height: 100%;

      .status-video {
        height: 66%;
        margin-bottom: 0;
        font-family: 'Megabyte-Medium', serif;
        color: $navy-blue-gradient !important;
        display: flex;
        align-items: flex-end;
        padding-bottom: .8rem;
        font-size: 1rem;
        line-height: 1.1rem;

        span.video-title-text {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          a {
            font-size: .9rem;
            color: $navy-blue-gradient !important;
          }
        }
      }

      .about-time {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 35%;

        .time-ago {
          color: $vrr_grey_medium;
        }

        .duration {
          color: $vrr_grey_medium;
          font-family: 'Megabyte-Bold', serif;
        }
      }
    }
  }
}
