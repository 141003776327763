@import "./../../../assets/vrr/scss/styles.scss";

.insights-filter-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 2rem;

  .filter-container-dropdowns {
    display: flex;
    flex-direction: column;
    margin-right: auto;
    width: 32rem;
  }
}

.filter-container-title {
  text-transform: uppercase;
  color: $vrr-grey-dark !important;
}

.filter-container-kpi-dropdowns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.publish-tabs-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.publish-tabs-container > div {
  position: absolute;
}

.single-button-publish-container {
  margin-top: auto;
  margin-bottom: auto;
}
