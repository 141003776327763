@import './../../../../assets/vrr/scss/styles.scss';

.media-plan-status {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: $vrr_white;
  border-radius: $vrr-border-radius-sm;

  .status {
    padding: 1.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;

    .status-score {
      color: $vrr-white;
      font-size: $vrr-highlight-text;
      font-weight: 900;
      padding: .3rem 1rem .1rem 1.5rem;
      border-radius: .5rem;
      letter-spacing: .4rem;

      &.cyan {
        background-color: $vrr-cyan;
      }

      &.grey {
        background-color: $vrr-grey-input;
      }
    }
  }

  .media-plan-save {
    button {
      background-image: unset !important;
      background-color: $vrr_red !important;
    }

    background-color: $vrr-grey-medium2;
    padding: 1.5rem 3rem;
    border-radius: 0 $vrr-border-radius-sm $vrr-border-radius-sm 0;
  }
}