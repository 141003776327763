@import '../../../../assets/vrr/scss/styles.scss';

.range-date-picker-control.active {
  border-color: $vrr_red !important;
}

.range-date-picker {
  margin-bottom: unset !important;
  height: 2.375rem;

  .range-date-picker-control {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 0.2rem;
    border-style: solid;
    border-width: 0.1rem;
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    min-height: 2.35rem;
    outline: 0 !important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    box-sizing: border-box;
    color: $vrr_black;
    padding: 0.3rem 0.5rem;
    margin-bottom: 0;

    .period-container {
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .icons-container {
      width: 11%;
      display: flex;
      justify-content: space-between;

      .separator {
        background-color: hsl(0, 0%, 80%);
        width: 0.05rem;
      }

      white-space: nowrap;
    }

    .icon-calendar {
      &:hover {
        color: $vrr_black;
      }

      i {
        padding-bottom: 0.1rem;
      }

      padding-right: 0.3rem;
      color: $vrr_grey_medium;
    }
  }

  .date-time-picker {
    top: 1.5rem;
    position: absolute;
    visibility: hidden;
  }

  .rdt {
    float: left;
    visibility: hidden;

    .white-content .form-control {
      color: $vrr_black;
      border-color: transparent !important;
    }

    .rdtPicker {
      display: block;
      width: max-content;

      .rdtNext {
        display: table-cell;
      }

      .dow,
      .rdtNext,
      .rdtPrev,
      .rdtSwitch,
      .rdtOld {
        color: $vrr_grey_medium;
      }

      .rdtDay {
        color: $vrr_black;
      }

      .rdtMonth.rdtActive {
        background-color: $vrr_red !important;
      }

      .rdtYear.rdtActive {
        background-color: $vrr_red !important;
      }

      .rdtDay.in-selecting-range {
        background-color: transparent;
      }

      .wrapper {
        display: flex;
        height: auto;

        .rdtDays {
          width: 15rem;
          display: flex;
          color: $vrr_black;
        }

        .controls {
          min-width: 190px;

          ul {
            width: 100%;

            li {
              button {
                text-align: initial;
              }
            }
          }
        }

        th.rdtPrev {
          display: revert;
        }
      }
    }
  }

  .rdt:nth-of-type(2) {
    display: none;
  }

  td.rdtDay.rdtToday.in-selecting-range::before {
    position: absolute;
    content: '';
    background-color: #fdb5b1;
    width: 100%;
    height: 95%;
    border: none;
    bottom: auto;
    right: auto;
    z-index: -1;
  }

  td.rdtDay.in-selecting-range::before {
    position: absolute;
    content: '';
    background-color: rgb(253, 181, 177);
    width: 10%;
    height: 12%;
    z-index: -1;
  }

  td:first-child.rdtDay.in-selecting-range::before {
    position: absolute;
    content: '';
    background-color: rgb(253, 181, 177);
    width: 11%;
    height: 12%;
    z-index: -1;
    left: 13rem;
  }

  td:last-child.rdtDay.in-selecting-range::before {
    position: absolute;
    content: '';
    background-color: rgb(253, 181, 177);
    width: 10%;
    height: 12%;
    z-index: -1;
    left: 25rem;
  }

  tbody .rdtDay.rdtActive.rdtActive {
    background-color: $vrr_red !important;
  }

  &.date-selected {
    tbody [class='rdtDay rdtActive rdtActive']:not(:last-child) {
      background-color: $vrr_red !important;

      &:before {
        position: absolute;
        content: '';
        background-color: rgb(253, 181, 177);
        width: 10%;
        height: 12%;
        z-index: -1;
      }
    }

    tbody [class='rdtDay rdtOld rdtActive rdtActive']:not(:last-child) {
      background-color: $vrr_red !important;

      &:before {
        position: absolute;
        content: '';
        background-color: rgb(253, 181, 177);
        width: 10%;
        height: 12%;
        z-index: -1;
      }
    }
  }
}
