@import './../../../assets/vrr/scss/styles.scss';

.multi-email {
  margin: 0;
  max-width: 100%;
  outline: 0;
  text-align: left;
  line-height: 1.21428571rem;
  padding: 0.4rem 0.5rem;
  background: $vrr_grey_medium2;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  font-size: 0.813rem;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-start;

  input {
    width: 100%;
    outline: none !important;
    border: 0 none !important;
    display: inline-block !important;
    line-height: 1;
    background: $vrr_grey_medium2;
    vertical-align: baseline !important;
    padding: 0.4rem 0.1rem !important;
  }

  input::placeholder {
    color: $vrr_grey_medium;
  }

  .label-email {
    line-height: 1;
    vertical-align: baseline;
    margin: 0.14285714em;
    background-color: $vrr_white;
    background-image: none;
    padding: 0.3rem 0.4rem;
    color: $vrr_grey_dark;
    text-transform: none;
    font-weight: 600;
    border: 0.1rem solid $vrr_grey_dark;
    border-radius: 1rem;
    transition: background 0.1s ease;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;

    &:first-child {
      margin-left: 0;
    }

    .email {
      max-width: 100%;
      overflow: hidden;
    }

    .remove,
    .remove:focus,
    .remove:hover,
    .remove:active {
      cursor: pointer;
      border: none;
      background: none;
      font-weight: 600;
      font-size: 1rem;
      color: $vrr_grey_dark;
      outline: none;
    }
  }
}
