.media-plan-filter .vrr-search-text-input {
  width: 15rem;
}

.media-plan-filter {
  .vrr_filter-search {
    margin-right: 0;
  }
  .vrr-button {
    margin: auto auto .6rem 0;
  }
}