@import '../../../assets/vrr/scss/styles.scss';

.vrr_filter-container {
  padding: 1rem 0 1rem 2.8rem;
  display: flex;
  justify-content: space-between;

  .vrr_filter-search {
    min-width: 25rem;
    margin: 0 3rem;
  }
}
