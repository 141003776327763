@import './../../../assets/vrr/scss/styles.scss';

.vrr-search-input-group {
  .vrr-dropdown__control {
    border-radius: 0 .4285rem .4285rem 0;
    max-width: 10rem;
  }

  .vrr-search-text-input, .vrr-search-icon-input {
    background-color: $vrr_white;
    border-color: $vrr-grey-input !important;
  }

  .vrr-search-icon-input {
    border-radius: .4285rem 0 0 .4285rem;
  }
}