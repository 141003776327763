@use 'sass:math';
// Colors
$vrr_red: #e93f3b;
$vrr_red_opacity: rgba($vrr_red, math.div(5, 10));
$vrr_grey_dark: #464767;
$vrr_grey_medium: #afafbd;
$vrr_grey_medium2: #e9e9ef;
$vrr_grey_light: #fdfdfd;
$vrr_white: #ffffff;
$vrr_yellow: #ffc958;
$vrr_mauve: #6c4cda;
$vrr_cyan: #2cd3bf;
$vrr_black: #3d3d3d;
$vrr_black_original: #000000;
$vrr_orange: #ff9705;
$vrr_navy: #464767;
$navy-blue-gradient: #344675;
$navy-blue-gradient-opacity: #344675b3;
$vrr-grey-card: #adb3c7;
$vrr-grey-input: #cccccc;
$vrr-grey-background: #f5f6fa;

// Borders
$vrr-border-radius-sm: 0.8rem;
$vrr-border-width-button: 0.125rem;
$vrr-border-width-small-button: 0.1rem;
$vrr-card-multi-radius: 0.6rem 2rem 0.6rem 0.6rem;

// Font sizes
$vrr-small-text: 0.85rem;
$vrr-normal-size-text: 1rem;
$vrr-highlight-text: 1.5rem;

// shadows
$vrr-box-shadow: 0.1rem 0.1rem 0.8rem 0.063rem $vrr_grey_medium2,
  -0.1rem -0.1rem 0.8rem 0.063rem $vrr_grey_medium2;

// Spacings
$vrr-spacing-between-blocks: 0.875rem;

/**
* Filter specifics colors for svg images
*
* Use the following codepen to edit a specific color
* https://codepen.io/sosuke/pen/Pjoqqp
*/
$vrr_white_svg_filter: invert(100%) sepia(0%) saturate(2%) hue-rotate(345deg)
  brightness(111%) contrast(101%);
$vrr_purple_svg_filter: invert(28%) sepia(94%) saturate(1386%)
  hue-rotate(236deg) brightness(91%) contrast(92%);
$vrr_turquoise_svg_filter: invert(93%) sepia(17%) saturate(3377%)
  hue-rotate(139deg) brightness(84%) contrast(94%);
$vrr_yellow_svg_filter: invert(95%) sepia(30%) saturate(5570%)
  hue-rotate(314deg) brightness(102%) contrast(101%);
$vrr_orange_svg_filter: invert(54%) sepia(78%) saturate(1587%) hue-rotate(0deg)
  brightness(103%) contrast(104%);
$vrr_red_svg_filter: invert(40%) sepia(81%) saturate(3626%) hue-rotate(340deg)
  brightness(103%) contrast(103%);
