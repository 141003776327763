@import '../../../assets/vrr/scss/styles.scss';

.card-container {
  box-shadow: rgba(100, 100, 111, 0.2) 0 0.438rem 1.813rem 0;
  border-radius: 0.4rem;
  padding: 1rem 0 0;
  background-color: $vrr_white;
}

.card-body {
  padding: 0px;
}

.padded-col {
  padding: 1rem 2rem 1rem;
}

.col-sm-8 {
  padding-left: 2rem;
}
