@import './../../../../assets/vrr/scss/styles.scss';

.budget-container {
  width: 60%;

  .budget-body {
    background-color: $vrr_white;
    border-radius: 0.8rem 0.8rem 0 0;
    padding: 1.5rem 1.5rem 2rem 1.5rem;
    gap: 2rem;
    display: flex;
    flex-direction: column;

    .budget-form {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }
  }
}