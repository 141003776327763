$height-btn: 2.9rem;
$padding-top: 0.938rem;

.btn-vrr-login {
  background: #ff4640;
  background-color: #ff4640;
  background-image: none !important;
  height: $height-btn;
  padding-top: $padding-top;
  padding: 0 0.25rem 0 0.25rem;
  margin-top: 1.1rem;
  font-size: 0.85rem;

  &.btn-primary:hover {
    background: #ff4640 !important;
    background-color: #ff4640 !important;
  }

  &.btn-primary:not(:hover) {
    background: #ff4640 !important;
    background-color: #ff4640 !important;
  }
}

.btn-simple {
  &.btn-vrr-login {
    border-color: #ff4640;
    border-width: 0.125rem;
    color: #ff4640;
    margin-left: 0px;
    height: $height-btn;
    padding-top: $padding-top;
  }
}

.background-compo {
  display: flex;
  padding: 0px;
  position: absolute;

  #img-red {
    position: relative;
    width: 90%;
  }

  #img-title {
    position: absolute;
    margin-top: 3.44rem;
    width: 90%;
    padding-left: 3.12rem;
  }
}

.card-body.forgot-card-body {
  padding: 0px !important;
  z-index: 1;

  .inside-card-body {
    text-align: left;
    padding: 0.938rem;
    margin-top: 8.3rem;

    & h4 {
      padding-left: 0.25rem;
      margin-bottom: 0.4rem;
      color: #ffffff;
      font-family: 'Megabyte-Bold';
      font-size: 1rem;
    }
  }
}

.vrr-options {
  display: flex;
  flex-direction: row-reverse;

  & a {
    text-decoration: underline;
    font-size: x-small;
  }

  & label input {
    margin-top: 0.07rem;
  }
}
