@import './../../../../../assets/vrr/scss/styles.scss';

.media-plan-config {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.5rem;
  background-color: $vrr_white;
  border-radius: $vrr-border-radius-sm;
  padding: 1.5rem;
  .config-part {
    flex-grow: 3;
    flex-basis: 26.5rem;

    display: flex;
    flex-direction: column;
    gap: 3rem;
    .sub-config-part {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .config-text {
        color: $navy-blue-gradient;
      }
      .highlighting {
        border-radius: $vrr-border-radius-sm;
        background-color: $vrr_grey_medium2;
        .highlighting-strong {
          font-family: 'megabyte-Bold';
          margin-bottom: 0;
        }
      }
      &.name-part {
        .highlight-title {
          margin-top: 1rem;
          padding: 0.5rem 1.1rem;
          .highlighting-strong {
            font-size: $vrr-highlight-text;
          }
        }
      }
      @include title-with-icon-media-plan-details;
      .sub-detail,
      p.highlighting-strong {
        &::first-letter {
          text-transform: capitalize;
        }
      }
      .highlight-detail, .highlight-title {
        position: relative;
        padding: 1.1rem;
        width: 100%;
        span.vrr-button {
          position: absolute;
          top: 0;
          right: 0;
          translate: 0 calc(-2.125rem / 2);
        }
        .sub-detail {
          margin-bottom: 0;
        }
      }
      &.optimization-part {
        .highlighting-group {
          display: flex;
          flex-direction: row;
          gap: 2.5rem;
        }
      }
    }
  }
  .img-part {
    flex-grow: 1;
    flex-basis: 18.75rem;
    min-height: 12.5rem;
    max-height: 28rem;
    background-color: $vrr_grey_dark;
    border-radius: $vrr-border-radius-sm;
    img {
      border-radius: $vrr-border-radius-sm;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
