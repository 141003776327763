@import "./../../../assets/vrr/scss/styles.scss";

table.notification-table {
  margin: 0 !important;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  thead tr,
  tbody tr {
    display: grid;
    grid-template-columns: min-content repeat(5, 1fr) min-content;
    grid-gap: 0.5rem;
  }

  tbody tr {
    padding: unset !important;
  }

  // Mixin for table header style
  @include creative-video-details-table-header;

  // Mixin for table body styles
  @include creative-video-details-table-body;

  .vrr-table-row {
    width: inherit;
    overflow: hidden;
  }

  .notification-status {
    width: .6rem;
    height: .6rem;
    border-radius: 0.8em;
    background-color: $vrr_red;

    &.read {
      opacity: 0;
    }
  }

  thead tr > *:last-child, thead tr > *:first-child {
    opacity: 0;
  }

  .notification-icon {
    font-size: 1.5rem;

    &.optimization {
      color: $vrr_cyan;
    }

    &.platform {
      color: $vrr_grey-input;
    }

    &.user_action {
      color: $vrr_orange;
    }

    &.refresh {
      color: $vrr_mauve;
    }

    &.setup {
      color: $vrr-navy;
    }
  }

  a {
    color: unset !important;

    &.notification-link {
      text-decoration: underline !important;
      text-decoration-color: unset;
    }
  }
}
