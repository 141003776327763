@import './../../../../../../../assets/vrr/scss/styles.scss';

.redirection-modal {
  i.circle-icon {
    background-color: $navy-blue-gradient;
    color: $vrr_white;
    padding: 0.5rem;
    border-radius: 50%;
    margin-right: 1rem;
  }

  &.modal-dialog {
    transform: unset !important;
    max-width: 40rem !important;
  }

  .redirection-modal-body {
    padding: 1rem 2rem;
  }

  .redirection-modal-add {
    padding: 0.5rem 0.9rem 0.5rem 0.8rem !important;
    margin: -0.4rem !important;
    display: flex;

    .icon-simple-add {
      margin-right: 0.4rem;
    }
  }
}