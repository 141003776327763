.creative-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, 18rem);
  grid-gap: 1.875rem;
  justify-content: space-between;
}

.no-data-found {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}
