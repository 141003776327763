@import './../../../../../assets/vrr/scss/styles.scss';

.labels-table {
  .account-labels-value ,
  .account-labels-input {
    margin-left: 0.7rem;
  }
  .account-labels-value {
    width: 100%;
    cursor: pointer;
    margin-bottom: unset;
  }
  .account-labels-input {
    &.form-check-input {
      position: unset;
      margin-top: unset;
    }
  }

  tbody:hover {
    tr:hover {
      cursor: pointer;
      background-color: $vrr_mauve !important;

      td {
        color: $vrr_white !important;
      }
    }
  }
}
