@import '../../../../../../assets/vrr/scss/styles';

.video-card-body {
  display: flex;
  flex-direction: row;

  img {
    width: 45%;
    margin-right: 2rem;
  }

  .video-card-right-section {
    margin-top: 1rem;
    width: 45%;

    .right-score {
      margin-bottom: 1.5rem;

      &.red {
        color: $vrr-red;
      }

      &.orange {
        color: $vrr-orange;
      }

      &.cyan {
        color: $vrr-cyan;
      }

      &.mauve {
        color: $vrr-mauve;
      }
    }

    .right-title {
      margin-bottom: .5rem;
      border-bottom: solid .08rem $vrr-grey-input;

      .right-section {
        font-weight: 600;
        color: $vrr-grey-input;
      }
    }

    .right-platform {
      color: $vrr-red;
      font-weight: 600;
    }
  }
}
