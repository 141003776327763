@import './../../../../assets/vrr/scss/styles.scss';

.viewer-line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button,
  button:focus,
  button:focus-visible,
  button:active,
  button:hover {
    border: none;
    background: none;
    margin-left: 1.2rem;
    outline: none !important;

    .revoke {
      color: $vrr_red_opacity;
      cursor: pointer;
      font-weight: 600;
    }
  }

  .viewer-infos {
    display: flex !important;
    flex-direction: row;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    .photo {
      display: flex;
      align-items: center;
      padding-right: 1.2rem;
    }

    .role,
    .email {
      width: 100%;
      font-size: 0.85rem;
    }

    .viewer {
      display: flex;
      flex-direction: column;
      .email {
        font-size: 0.8rem;
        color: $vrr_grey_medium;
      }

      .username {
        font-size: 0.9rem;
        font-weight: 600;
      }
    }
  }
  .viewer-action {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
}
