@import './../../../../../../../assets/vrr/scss/styles.scss';

.default-target-modal {
  width: 50rem !important;
  i.circle-icon {
    background-color: $navy-blue-gradient;
    color: $vrr_white;
    padding: 0.5rem;
    border-radius: 50%;
    margin-right: 1rem;
  }
  .default-target-body-modal {
    margin: 3rem 1rem;
    .vrr-dropdown {
      width: 3rem;
      margin-bottom: 2rem;
    }
  }
}