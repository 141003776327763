span.vrr-button {
  button.media-plan-details-edit-button {
    span.tim-icons {
      top: 0;
    }
    span:nth-child(2) {
      text-transform: capitalize;
    }
  }
}
