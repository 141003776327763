@import '../../assets/vrr/scss/variables';

.white-content {
  .ReactTable {
    overflow: auto;

    .rt-table {
      display: block;
      width: 100%;
      overflow: auto;
    }

    .rt-thead .rt-resizable-header-content {
      padding-right: 1.5rem;
    }

    .rt-thead, .rt-tbody {
      display: contents;
    }

    .rt-tr {
      display: table-row;
    }

    .rt-td,
    .rt-thead .rt-th {
      text-align: center;
    }

    .-pagination {
      .-previous,
      .-next {
        .-btn,
        .-btn:hover,
        .-btn:focus,
        .-btn:active,
        .-btn.active,
        .-btn:active:focus,
        .-btn:active:hover,
        .-btn.active:focus,
        .-btn.active:hover {
          background-color: $vrr_navy !important;
          background-image: linear-gradient(
                          to bottom left,
                          $navy-blue-gradient,
                          $vrr_navy,
                          $navy-blue-gradient
          ) !important;
        }
      }
    }
  }
}

.react-select__input, .react-select__input-container {
  display: none !important;
}