.card-list-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, 20rem);
  grid-gap: 1.875rem;
  justify-content: space-between;
}

.no-insights {
  text-align: center;
  margin-top: 10rem;
}
