@import './../../../../../../../../assets/vrr/scss/styles.scss';

div.vrr-input-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 0;
  div {
    margin-bottom: 0;
    @include input-on-edit-mode-in-modal($vrr_grey_medium);
    input {
      width: 100% !important;
    }
    input,
    input:focus {
      color: $vrr_navy !important;
      background-color: $vrr_white;
    }
  }
  small {
    margin-top: 0;
    color: $vrr_navy !important;
    padding-left: 0.8rem;
  }
  div:nth-child(2) {
    margin-top: 0;
    padding-left: 0.8rem;
    font-size: 0.75rem;
  }
}
