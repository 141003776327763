@import './../../../../assets/vrr/scss/styles.scss';

.quality-metrics {
  display: flex;
  flex-wrap: wrap;

  .quality-metric-column {
    width: fit-content;
    text-align: center;
    align-items: center;
    padding: .5rem;
    vertical-align: center;
  }
}