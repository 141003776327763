@import './../../../assets/vrr/scss/styles.scss';

.section-hide {
  display: none;
}

.loader-gallery {

  &.section-hide {
    display: none;
  }

  display: grid;
  grid-template-columns: repeat(auto-fill, 22rem);
  grid-gap: 1rem;
  -webkit-animation: flickerAnimation 1.5s infinite;
  -moz-animation: flickerAnimation 1.5s infinite;
  -o-animation: flickerAnimation 1.5s infinite;
  animation: flickerAnimation 1.5s infinite;
  justify-content: space-between;

  .loader-gallery-card {
    width: 22rem;
    height: 22rem;
    background-color: $vrr-grey-medium2;
  }

  @keyframes flickerAnimation {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-o-keyframes flickerAnimation {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-moz-keyframes flickerAnimation {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-webkit-keyframes flickerAnimation {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}