@import '../../../../../assets/vrr/scss/styles';

.controls {
  margin: 0.2rem 0.5rem;
  display: flex;

  ul {
    display: grid;
    margin: 0;
    padding: 0;

    li {
      display: flex;
      padding: inherit;

      button {
        display: -webkit-box;
        width: 100%;
        height: 100%;
        color: $vrr_black;
      }
    }
  }
}
