.user-profile-components {
  display: flex;
}

.user-profile-information {
  margin-bottom: 3rem;
}

.user-profile-components-page {
  width: 80%;
}
