@import '../../../../../assets/vrr/scss/styles';

.video-gallery-folder {
  cursor: pointer;
  @include gallery-card;
  display: grid;
  align-items: center;
  background-color: $vrr_white;

  .checkbox-card {
    padding: 0.8rem;
    position: absolute;
    width: 100%;
    top: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @include checkbox-in-galery-card;
  }

  .folder-info.card-body {
    margin-top: 2rem;
    text-align: center;

    .folder-name.card-title {
      color: $navy-blue-gradient;
      margin-bottom: 0;
    }
  }

  .folder-footer {
    margin: .2rem;
    padding: .5rem;
    display: flex;
    justify-content: space-between;

    .footer-folder-icon {
      margin: 0 .3rem 0 .2rem;
    }

    .folder-items {
      font-size: .813rem;
      font-family: 'Megabyte-Bold', serif;
      padding: .2rem .5rem;
    }

    .folder-info-circle {
      font-size: 1.2rem;
    }
  }
}