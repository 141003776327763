@import './../../../../../../assets/vrr/scss/styles.scss';

.video-card-footer {
  display: flex;
  flex-direction: row;
  color: $vrr-navy;
  justify-content: space-around;
  background-color: $vrr_grey_medium2;
  padding: 1rem;
  border-radius: 0 0 .5rem .5rem;

  .card-footer-checkbox {
    display: none;
  }

  .card-footer-device:hover {
    box-shadow: 0 .25rem .375rem rgba(50, 50, 93, 0.11), 0 .063rem .188rem rgba(0, 0, 0, 0.08);
  }

  .card-footer-device {
    padding: .5rem .2rem;
    border-radius: .5rem;
    margin: .5rem;
    border: solid .1rem $vrr-navy;
    cursor: pointer;

    &.is-active {
      background-color: $vrr-navy;

      svg {
        fill: $vrr-white;
        color: $vrr-white;
        stroke: $vrr-white;
      }
    }

    svg {
      fill: $vrr-navy;
      color: $vrr-navy;
      stroke: $vrr-navy;
    }
  }
}