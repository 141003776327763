@font-face {
  font-family: 'megabyte-regular';
  src: url('../../Megabyte/fonts/Megabyte-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Megabyte-Bold';
  src: url('../../Megabyte/fonts/Megabyte-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Megabyte-Medium';
  src: url('../../Megabyte/fonts/Megabyte-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Nucleo';
  src: url('../../CreativeTim/fonts/nucleo.eot');
  src: url('../../CreativeTim/fonts/nucleo.eot') format('embedded-opentype'),
  url('../../CreativeTim/fonts/nucleo.woff2') format('woff2'),
  url('../../CreativeTim/fonts/nucleo.woff') format('woff'),
  url('../../CreativeTim/fonts/nucleo.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
