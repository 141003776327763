.action-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 1rem;
  max-height: 2.5rem;

  .actions_add_tag,
  .actions_new_folder,
  .action-bar-button,
  .other-actions {
    margin-top: 0;
    margin-bottom: 0;
    padding-right: 0.688rem;
  }

  .actions_add_tag,
  .actions_new_folder,
  .action-bar-button {
    padding-left: 0.688rem;
  }

  .other-actions {
    .btn {
      padding: 0.7rem 1rem !important;
      margin: unset;
    }
  }
}

.archive-modal {
  .vrr-modal-header {
    padding: 0;
  }
}
