@import './../../../../assets/vrr/scss/styles.scss';

.video-selection {
  background-color: $vrr_white;
  border-radius: 0.8rem 0.8rem 0 0;
  padding: 1.5rem;
  gap: 2rem;
  display: flex;
  flex-direction: column;

  .video-selection-grid {
    display: grid;
    justify-content: space-around;
    grid-template-columns: repeat(auto-fill, 35rem);
    .video-card {
      margin-bottom: 2rem;
    }
  }
}