@import './../../../../assets/vrr/scss/styles.scss';

.creative-modal-header {
  i.circle-icon {
    background-color: $navy-blue-gradient;
    color: $vrr_white;
    padding: 0.5rem;
    border-radius: 50%;
    margin-right: 1rem;
  }
}