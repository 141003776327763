@import './../../../../../../../assets/vrr/scss/styles.scss';

.target-modal {
  &.modal-dialog {
    transform: unset !important;
    max-width: 60rem !important;
  }

  div:first-child {
    div:first-child {
      h5 {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        align-items: center;
        span:first-child {
          i {
            background-color: $navy-blue-gradient;
            color: $vrr_white;
            padding: 0.5rem;
            border-radius: 50%;
          }
        }
        span:nth-child(2)::first-letter {
          text-transform: uppercase;
        }
      }
    }
  }
}
