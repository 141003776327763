@import '../../../../../../../../assets/vrr/scss/styles';

.optimization-body-modal {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem 0 0 2rem;

  .optimization-body {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 2rem;
    align-items: center;

    .optimization-rank {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      align-items: center;
      padding: 0 1rem;
    }

    .optimization-new-rank {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      align-items: center;
      background-color: rgba(70, 71, 103, .1);
      padding: .4rem 1rem;
      border-radius: .5rem;

      span {
        white-space: nowrap;
      }

      .optimization-remove {
        padding: .5rem;
      }
    }

    .vrr-dropdown {
      min-width: 10rem;
    }

    .optimization-input {
      .vrr-input-container {
        margin-bottom: unset !important;

        input, .vrr-currency-input {
          border-width: .09rem !important;
          border-color: $vrr-grey-input !important;
          background-color: $vrr-white;
        }
      }
    }

    .optimization-dropdown {
      .vrr-dropdown {
        border-bottom: .1rem solid;
      }

      .vrr-dropdown__control {
        border-color: unset;
        border-radius: unset;
        border-style: unset;
        box-shadow: none;
        background-color: unset;

        .vrr-dropdown__indicator-separator {
          display: none;
        }
      }
    }
  }
}
