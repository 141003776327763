@mixin checkbox-in-galery-card {
  input[type='checkbox'] {
    display: none;
  }

  input[type='checkbox'] + label.check span {
    position: relative;
    display: inline-block;
    width: 1.1rem;
    height: 1.1rem;
    background-color: $vrr_grey_light;
    border: solid 0.063rem $vrr_grey_medium;
    border-radius: 0.188rem;
    font-size: 0.7rem;
    line-height: 1.1rem;
  }
  input[type='checkbox']:checked + label.check span::after {
    position: absolute;
    content: '\2713';
    color: $vrr_white;
    display: inline;
    top: -0.063rem;
    left: 0.15rem;
    margin: auto;
    font-weight: bold;
  }

  input[type='checkbox']:checked + label.check span {
    background-color: $vrr_cyan;
  }
}

@mixin input-on-edit-mode-in-modal($color) {
  input {
    border: unset !important;
    border-style: solid !important;
    border-width: 0.125rem !important;
    border-color: $color !important;
  }
}
