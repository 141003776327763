@import "./../../../assets/vrr/scss/styles.scss";

.publish-unhide-all,
.publish-unhide-all:hover,
.publish-unhide-all:active,
.publish-unhide-all:active:hover,
.publish-unhide-all:target,
.publish-unhide-all:focus {
  background: $vrr_white !important;
  color: $vrr_grey_dark !important;
  border: solid 0.1rem $vrr_grey_dark !important;
  transition: none !important;
  margin-right: 2rem;
  border-radius: 6px;
}

.publish-unhide-all:active:hover {
  background: $vrr_grey_dark !important;
  color: $vrr_white !important;
}
