// Switch languages
.switch-language-container {
  width: 100%;
  text-align: center;
  padding-left: 0px;
  padding-right: 0px;

  .selected-language {
    font-weight: bold !important;
  }

  .switch-language {
    cursor: pointer;
    .language-separator {
      margin-right: 0.5rem;
      font-weight: normal !important;
    }
  }
}
