@import "./../../../assets/vrr/scss/styles.scss";

.notification-container:before {
  right: 1.4rem !important;
}

.notification-container {
  padding: .4rem .7rem .3rem;
  width: 22rem;
  border-radius: .3rem;

  .notification-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;

    .notification-all {
      margin-right: 0;
      padding-right: 0;
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-weight: normal;
      text-decoration: underline;
    }

    h5 {
      font-family: 'Megabyte-Bold', serif;
      color: $vrr-grey-dark;
    }
  }

  .notification-body {
    display: flex;
    flex-direction: column;
  }
}
