.deck-metrics {
  .selected-metrics.card-deck {
    display: grid;
    grid-template-columns: repeat(auto-fill, 18.75rem);
    grid-gap: 1rem;
    margin: 0;
    justify-content: space-between;
  }

  .selected-metrics {

    .vrr-add-card {
      align-items: center;
      padding-top: .8rem;
      margin: 0;
    }
  }
}
