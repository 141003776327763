.carousel {
  min-width: 23rem;
  display: flex;
  z-index: -10;
  .carousel-inner {
    .carousel-item {
      padding: 8px;
      height: 2.75rem;
      .last-update-text {
        padding: 0px;
        bottom: 0;
        p {
          margin-bottom: -0.25rem;
          height: 2.75rem;
        }
      }
    }
  }
  .carousel-previous-button,
  .carousel-next-button {
    margin-top: 0.45rem;
  }
}
