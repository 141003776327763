@import './../../../../../../../../assets/vrr/scss/styles.scss';

table.redirection-table {
  thead {
    tr {
      display: flex;
      th {
        width: 28.125rem;
        div {
          div {
            div:first-child {
              display: flex;
              flex-direction: row;
              gap: 0.5rem;
              p {
                color: $vrr_grey_medium;
                font-family: 'Megabyte-Bold';
              }
            }
            div > svg {
              stroke: $vrr_navy;
              fill: $vrr_navy;
            }
          }
          div:nth-child(2) {
            div {
              @include input-on-edit-mode-in-modal($vrr_grey_medium);
            }
          }
        }
      }
    }
  }
  tbody {
    tr {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;
      td {
        color: $vrr_navy;
        vertical-align: baseline !important;
      }
      td:first-child {
        width: 28.125rem;
        font-family: 'megabyte-regular';
        p {
          padding-left: 0.7rem;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      td:last-child {
        padding: 0;
        div {
          span {
            button {
              padding: 0.5rem;
            }
          }
        }
      }
    }
    tr:has(input) {
      border-radius: 1.25rem;
      background-color: $vrr_grey_medium2;
      padding-top: 0.8rem;
      padding-bottom: 0.8rem;
      td {
        div {
          margin-bottom: 0;
          div {
            margin-bottom: 0;
            @include input-on-edit-mode-in-modal($vrr_grey_medium);
            input {
              width: 100% !important;
            }
            input,
            input:focus {
              color: $vrr_navy !important;
              background-color: $vrr_white;
            }
          }
        }
      }
    }
  }
}
