@import './../../../../../assets/vrr/scss/styles.scss';

.budget-inputs {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .budget-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
    justify-content: space-between;
    width: 23rem;

    .budget-funnel-label {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: .5rem;

      span {
        color: $vrr-grey-medium;
      }

      .budget-funnel {
        width: 1rem;
        height: 1rem;
        border-radius: 5rem;

        &.total {
          background-color: $vrr-red;
        }

        &.top {
          background-color: $vrr-cyan;
        }

        &.middle {
          background-color: $vrr-orange;
        }

        &.bottom {
          background-color: $vrr-mauve;
        }

        &.unspecified {
          background-color: $vrr-navy;
        }
      }
    }
  }
}