@import './../../../../../../../../../../../assets/vrr/scss/styles.scss';

.audience-section {
  .audience-title {
    margin-bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: $vrr_grey_medium2;
    padding: 1rem;
    span.audience-name {
      text-transform: uppercase;
      font-family: 'Megabyte-Bold';
      color: $vrr_grey_dark;
      font-size: 0.8rem;
    }
  }
  .audience-categories {
    padding: 0.5rem 1rem 0.5rem 1rem;
    background-color: $vrr_white;
  }
}
