@import './../../../../assets/vrr/scss/styles.scss';

.vrr-table-pagination {
  margin: 1rem;
  display: flex;
  flex-wrap: wrap;

  .vrr-button {
    margin: .2rem;
  }
}