.vrr-navbar {
  z-index: 2;
  padding-right: 0;
  padding-top: 1rem;
  height: auto;
  .vrr-navbar-container {
    padding-right: 0;
    padding-left: 0;
    display: grid;
    grid-template-columns: 6rem 4fr auto;
    align-items: start;
    grid-row-gap: 1rem;
    .navbar-wrapper {
      grid-column: 2;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      grid-gap: 1rem;
    }
  }
  button.navbar-toggler {
    height: 2.375rem;
  }
  .vrr-navbar-collapse {
    min-height: 2.375rem;
    ul {
      li {
        a {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
        }
      }
    }
  }
}
.vrr-navbar.bg-white {
  .vrr-navbar-container {
    .vrr-navbar-collapse {
      grid-column-start: 2;
    }
  }
}
