@import './../../../../../../../../../assets/vrr/scss/styles.scss';

.target-list-inputs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .target-list-input {
    margin-top: 1rem;
    width: 9rem;

    .target-input {
      font-size: .7rem;
      color: $vrr-grey-medium;
      font-weight: 600;
    }
  }
}