@import './../../../../../../../../assets/vrr/scss/styles.scss';

.cta-button-tooltip {
  .tooltip {
    .tooltip-inner {
      width: 35rem !important;
      max-width: unset !important;
    }
  }
}
