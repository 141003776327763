@import './../../../../../assets/vrr/scss/styles.scss';

.status-metric-content {
  background-color: $vrr-grey-background;
  border-radius: .5rem;
  display: flex;
  flex-direction: row;
  padding: 1.8rem 1.5rem;
  gap: 1rem;
  align-items: center;
  white-space: nowrap;

  .metric-status {
    color: $vrr-white;
    padding: .2rem 1.5rem;
    border-radius: 5rem;

    &.red {
      background-color: $vrr-red;
    }

    &.orange {
      background-color: $vrr-orange;
    }

    &.green {
      background-color: $vrr-cyan;
    }

    &.grey {
      background-color: $vrr-grey-card;
    }
  }

  .metric-text {
    font-weight: bold;
  }
}