@import './../../../assets/vrr/scss/styles.scss';

.gdpr-page-container {
  display: flex;
  flex-direction: row;

  .gdpr-page-content {
    margin: 1.25rem;

    h1 {
      text-align: center;
      margin: 2rem 8rem;
    }

    h1, h2, h3 {
      color: $vrr-red;
      margin-top: 2rem;
    }

    a {
      color: $vrr-red;
    }

    .vrr-dropdown {
      width: 3rem;
    }
  }
}