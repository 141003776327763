.media-plan-details-container-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
  padding-bottom: 1rem;

  .media-plan-details-container-left {
    flex-basis: 37.5rem;
    flex-grow: 3;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .media-plan-details-container-right {
    flex-basis: 18.75rem;
    flex-grow: 1;
  }
}
