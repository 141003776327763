@import '../../../../../../../../assets/vrr/scss/styles.scss';

.goal-list-container {
  .goal-list-header {
    display: flex;
    justify-content: space-between;
    border-bottom: solid 0.1rem $vrr-grey-input;
    width: 40%;
    margin-bottom: 1rem;
    p {
      color: $vrr_grey_medium;
    }

    .goal-list-info-icon {
      stroke: $vrr_grey_medium;
      fill: $vrr_grey_medium;
    }
  }

  .goal-list-options {
    display: flex;
    flex-direction: column;
    .form-check-label {
      line-height: 1.7rem;
    }
    .form-check-input {
      opacity: 1;
      visibility: visible;
    }
  }
}
