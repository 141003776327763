@import './../../../../../../../assets/vrr/scss/styles.scss';

.cta-modal {
  div.modal-content {
    div.cta-modal-body {
      padding: 1rem 2rem;
    }
    div.vrr-modal-footer {
      padding-top: 1.5rem;
    }
  }
}
