@import './../../../assets/vrr/scss/styles.scss';

.vrr-button-group {
  button {
    border-radius: 5rem;
    padding: .5rem 1rem;
    font-weight: 400;
  }

  .inactive {
    background: $vrr-white !important;
    color: $vrr_navy;
    border-color: $vrr_navy !important;
    border: solid .1rem;
    transform: none !important;
  }

  .active {
    border-color: $vrr_navy !important;
    border: solid .1rem;
    transform: none !important;
  }
}
