@import './../../../assets/vrr/scss/styles.scss';

.creative-video-details {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  overflow: unset;

  .section-container {
    @include creative-video-details-block;
    flex-grow: 1;
    flex-basis: unset;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .top-section {
    .right-section {
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }
  }

  .bottom-section, .top-section {
    display: flex;
    flex-direction: row;
    gap: 2rem;
  }

  @media (max-width: 1500px) {
    .bottom-section, .top-section {
      flex-direction: column;
    }
  }
}

div.spacer {
  height: $vrr-spacing-between-blocks;
}
