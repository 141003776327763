@import './../../../../../../assets/vrr/scss/styles.scss';

.modal-boost {
  .modal-content {
    .modal-boost-body {
      input:focus {
        border-color: $vrr_red;
        box-shadow: $vrr_red 0 0 0 1px;
      }
      .modal-boost-body-container {
        .budgetlist-container {
          .budget-boost-list-title {
            display: flex;
            justify-content: space-between;
            width: 100%;
          }
          .budget-boost-list-sub-item {
            width: 100%;
            display: flex;
            justify-content: space-between;
            border-bottom: solid 1px $vrr_grey_medium2;
            .error {
              color: $vrr_red;
            }
            .boost-input {
              width: 30%;
            }
          }
        }
      }
    }
  }
}
