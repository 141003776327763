@import '../../../../assets/vrr/scss/styles.scss';

div.vrr-modal-footer {
  display: flex;
  flex-direction: column;
  background-color: $vrr_grey_medium2;
  padding: 1rem 1.5rem !important;

  div {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    gap: .5rem;

    span {
      button {
        margin: .5rem;
        padding: .5rem 1.2rem !important;
      }
    }

    :last-child {
      button {
        background-color: $vrr_red !important;
        background-image: unset !important;
      }
    }
  }
}
